var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"px-3 lg:px-12 m-auto"},[_c('div',{staticClass:"w-full"},[_c('PurchasedStudies',{attrs:{"is-loading":_vm.isCoachStudiesLoading}}),_c('RandomizedBanner',{attrs:{"banners":_vm.banners}}),_c('DataTable',{attrs:{"title":_vm.$route.meta.name,"is-loading":_vm.globalLoading,"columns":_vm.tableColumns,"table-data":_vm.exams,"pagination":_vm.pagination,"coach-studies":_vm.coachStudies},on:{"page-change":_vm.changePage,"filter":_vm.filterData,"clear-filters":_vm.clearFilters,"update-filters":_vm.updateFilters},scopedSlots:_vm._u([{key:"rowActions",fn:function({ item }){return [(item.study_id === _vm.smsStudyType)?_c('div',{staticClass:"md-toolbar-row md-toolbar-section-end"},[_c('md-button',{staticClass:"md-info",attrs:{"to":{ name: 'SmsExam.SmsShow', params: { id: item.id } }}},[_c('md-icon',[_vm._v("visibility")]),_c('div',{staticClass:"hidden lg:block"},[_vm._v(_vm._s(_vm.$t("exam.see")))])],1),_c('md-menu',{attrs:{"md-size":"small","md-offset-x":-60,"md-offset-y":0}},[_c('md-button',{staticClass:"md-simple md-icon-button",attrs:{"md-menu-trigger":""}},[_c('md-icon',[_vm._v("more_vert")])],1),_c('md-menu-content',[_c('md-menu-item',{class:{ disabled: !item.rounds[0].is_finished },attrs:{"md-disabled":!item.rounds[0].is_finished,"to":item.rounds[0].is_finished
                    ? {
                        name: 'SmsExam.SmsResults',
                        params: { id: item.id, roundId: item.rounds[0].id },
                      }
                    : {}}},[_vm._v(" "+_vm._s(_vm.$t("exam_round.see_results"))+" ")]),_c('md-divider'),_c('md-menu-item',{staticClass:"md-list-item-router",on:{"click":function($event){return _vm.askForDelete(item)}}},[_c('a',[_vm._v(_vm._s(_vm.$t("exam.delete")))])])],1)],1)],1):_c('div',{staticClass:"md-toolbar-row md-toolbar-section-end"},[_c('md-button',{staticClass:"md-info",attrs:{"to":{ name: 'Exam.Show', params: { id: item.id } }}},[_c('md-icon',[_vm._v("visibility")]),_c('div',{staticClass:"hidden lg:block"},[_vm._v(_vm._s(_vm.$t("exam.see")))])],1),_c('md-menu',{attrs:{"md-align-trigger":"","md-direction":"bottom-end"}},[_c('md-button',{staticClass:"md-simple md-icon-button",attrs:{"md-menu-trigger":""}},[_c('md-icon',[_vm._v("more_vert")])],1),_c('md-menu-content',[_c('md-menu-item',{class:{ disabled: item.is_draft },attrs:{"to":!item.is_draft
                    ? {
                        name: 'Respondents.Additional',
                        params: {
                          id: _vm.lastExamRound(item.rounds).exam_id,
                          examRoundId: _vm.lastExamRound(item.rounds).id,
                        },
                      }
                    : {}}},[_vm._v(_vm._s(_vm.$t("exam_round.manage_respondents")))]),_c('md-menu-item',{class:{ disabled: !_vm.isChangeDateActive(item) },attrs:{"to":_vm.isChangeDateActive(item)
                    ? {
                        name: 'Exam.Show',
                        params: { id: item.id, openExtendModal: true },
                      }
                    : {}}},[_vm._v(_vm._s(_vm.$t("exam_round.edit_end_date")))]),(_vm.hasExamRound(item.rounds))?_c('md-menu-item',{class:{
                  disabled: !_vm.lastExamRound(item.rounds).is_finished,
                },attrs:{"to":_vm.lastExamRound(item.rounds).is_finished
                    ? {
                        name: 'Exam.Results',
                        params: {
                          id: _vm.lastExamRound(item.rounds).exam_id,
                          roundId: _vm.lastExamRound(item.rounds).id,
                        },
                      }
                    : {}}},[_vm._v(" "+_vm._s(_vm.$t("exam_round.see_results"))+" ")]):_vm._e(),_c('md-divider'),_c('md-menu-item',{staticClass:"md-list-item-router",on:{"click":function($event){return _vm.askForDelete(item)}}},[_c('a',[_vm._v(_vm._s(_vm.$t("exam.delete")))])])],1)],1)],1)]}}])}),(!_vm.globalLoading)?_c('Alert',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("dashboard.referal_alert_title"))+" ")]},proxy:true},{key:"description",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("dashboard.referal_alert_description"))+" ")]},proxy:true}],null,false,2741121513)}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }