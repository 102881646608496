<template>
  <EditProfileForm
    v-if="!isLoading"
    :errors="errors"
    @form-submit="submit"
    @cancel="$router.push({ name: 'Dashboard' })"
    :form-title="$t('profile.edit_profile')"
  />
</template>
<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { successAlert } from "@/helpers/swal";
import EditProfileForm from "./EditProfileForm";

export default {
  name: "EditProfile",
  components: {
    EditProfileForm,
  },
  data() {
    return {
      isLoading: false,
    };
  },

  computed: {
    ...mapState("auth", ["user"]),
  },
  async created() {
    try {
      this.startLoading();
      this.isLoading = true;
      await this.getCoachProfile({ coachId: this.user.id });
      this.finishLoading();
      this.isLoading = false;
    } catch (e) {
      if (e.response?.status === 422) return;
      console.error(e);
    }
  },
  methods: {
    ...mapActions("coachProfile", ["getCoachProfile", "updateProfile", "changeUserPassword"]),
    ...mapActions("auth", ["logout"]),
    ...mapMutations("layout", ["startLoading", "finishLoading"]),

    async submit(payload) {
      try {
        await this.updateProfile(payload);
        if (payload.emailChanged) {
          await this.logout();
          this.$router.push({ name: "Auth.Login" });
          await successAlert({ title: this.$t("profile.email_changed") });

          return;
        }
        await successAlert({ title: this.$t("profile.edit_success") });
      } catch (e) {
        if (e.response?.status === 422) return;
        console.error(e);
      }
    },
  },
};
</script>
