<template>
  <div class="studies-menu">
    <md-button class="md-raised md-info" :md-ripple="false" @click="$emit('showStudiesDialog')">
      <md-icon>shopping_cart</md-icon>
      {{ $t("studies.buy_study") }}
    </md-button>
    <md-menu
      class="studies-menu__menu"
      md-align-trigger
      md-direction="bottom-end"
      :md-offset-y="5"
      :md-offset-x="-5"
    >
      <md-button class="md-raised md-info" :md-ripple="false" md-menu-trigger>
        {{ $t("survey.use_study") }}
        <md-icon>expand_more</md-icon>
      </md-button>
      <md-menu-content>
        <template v-for="coachStudy in coachStudies">
          <md-menu-item
            v-if="coachStudy.all_count - coachStudy.used_count > 0"
            :key="coachStudy.id"
          >
            <router-link
              v-if="coachStudy.study.id === smsStudyType"
              :to="{
                name: 'SmsExam.SmsCreate',
                params: { studyId: coachStudy.study.id },
              }"
              class="flex-col"
            >
              <div class="text-base capitalize">
                {{ coachStudy.study.name }}
              </div>
              <div class="text-xs text-green capitalize">
                {{
                  $t("exam.number_left", {
                    number: coachStudy.all_count - coachStudy.used_count,
                  })
                }}
              </div>
            </router-link>
            <router-link
              v-else
              :to="{
                name: 'Exam.Create',
                params: { studyId: coachStudy.study.id },
              }"
              class="flex-col"
            >
              <div class="text-base capitalize">
                {{ coachStudy.study.name }}
              </div>
              <div class="text-xs text-green capitalize">
                {{
                  $t("exam.number_left", {
                    number: coachStudy.all_count - coachStudy.used_count,
                  })
                }}
              </div>
            </router-link>
          </md-menu-item>
        </template>
      </md-menu-content>
    </md-menu>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

const smsStudyId = 2;

export default {
  name: "StudiesMenu",
  computed: {
    ...mapState("coachStudies", ["coachStudies"]),

    smsStudyType() {
      return smsStudyId;
    },
  },
  async created() {
    try {
      await this.getCoachStudies();
    } catch (e) {
      console.error(e);
    }
  },
  methods: {
    ...mapActions("coachStudies", ["getCoachStudies"]),
  },
};
</script>

<style lang="scss" scoped>
.studies-menu {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;

  @media only screen and (min-width: 480px) {
    flex-direction: row;
    margin-left: auto;
  }
  &__menu {
    display: flex;
    flex-direction: column;

    @media only screen and (min-width: 480px) {
      flex-direction: row;
      width: auto;

      button:last-of -type {
        margin-top: 0;
      }
    }
  }
}
</style>
