<template>
  <div id="page-container" class="pb-24 flex flex-col items-center pt-10">
    <router-link :to="{ name: 'Dashboard' }">
      <img :src="require('@/assets/images/logo.svg')" width="180px" alt="" />
    </router-link>
    <router-view :key="$route.path" />
  </div>
</template>
<script>
import DashboardLayout from "@/pages/Dashboard/Layout/DashboardLayout";

export default {
  extends: DashboardLayout,
};
</script>
