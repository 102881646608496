export default {
  create: "Konfiguracja badania SMS",
  edit: "Edytuj badanie SMS",
  summary: "Sprawdź wprowadzone dane",
  send_exam: "Wysyłanie badania",
  sms_question: "Pytanie",
  send_time: "Godzina wysłania sms",
  back: {
    title: "Czy na pewno chcesz cofnać?",
    text: "Wprowadzona konfiguracja nie zostanie zapisana",
    confirmButtonText: "Cofnij",
  },
  step_2: {
    space: " ",
    exam_start: "Początek badania",
    datepicked: "Wybierz dni",
    ranges: "Ustaw od-do",
    body: "Treść SMS",
    time: "Godzina wysłania SMS",
  },
  step_3: {
    title: "Utwórz nowe badanie SMS",
    days_count: "Ilość dni",
  },
  cancel: {
    title: "Czy na pewno chcesz anulować badanie sms?",
    text: "Upewnij się czy chcesz anulować kolejkę sms.",
    ok: "Anuluj badanie",
    cancel: "Wróć",
    success: "Pomyślnie anulowano kolejkę sms",
  },
};
