export default {
  see: "Podgląd",
  name: "Badanie",
  show: "Pokaż",
  status: "Status",
  end_date: "Data zakończenia",
  progress: "Postęp",
  in_progress: "W trakcie",
  finished: "Zakończone",
  results: "Wyniki",
  start: "Rozpocznij badanie",
  statuses: {
    draft: "Szkic",
    report_ready: "Raport gotowy",
    first_round_progress: "W trakcie badania",
    first_round_finished: "Zakończono badanie",
    second_round_progress: "W trakcie ewaluacji",
    second_round_finished: "Zakończono waluację",
    report_sent: "Wysłano raport",
  },
  edit: "Edycja badania",
  edit_participant: "Edycja uczestnika",
  delete: "Usuń badanie",
  delete_confirmation:
    "Czy na pewno chcesz usunąć badanie dla {firstName} {lastName} wraz z wszystkimi jego danymi?",
  number_left: "Pozostało {number} badań.",
  this_is_draft: "To jest szkic badania.",
  click_here: "Dokończ tworzenie badania",

  exam_created: "Tworzenie badania",
  what_you_want_to_do: "Co chcesz dalej zrobić?",
  continue: "Kontunuuj",
  save_draft: "Zapisz jako szkic",
  no_second_round_title: "Wystąpił błąd!",
  no_second_round: "Nie można rozpocząć ewaluacji!",
  create_round: "Konfiguracja dat",
  question_triggered_by: "Pytanie wywoływane przez odpowiedź",
  unsettled: "Nieustalone",
  fake_exam: "To badanie zostało wygenerowane w celach testowych",
  no_answer: "Brak odpowiedzi",
  respondent_answers: "Odpowiedzi respondenta",
};
