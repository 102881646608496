import api from "@/helpers/api";

export default {
  async getRules({ commit }) {
    const response = await api.get(`/text-pages?url=rules`);
    const data = response.data.data;

    commit("setRules", data);
  },

  async getPolicy({ commit }) {
    const response = await api.get(`/text-pages?url=policy`);
    const data = response.data.data;

    commit("setPolicy", data);
  },

  async getCookies({ commit }) {
    const response = await api.get(`text-pages?url=cookies`);
    const data = response.data.data;

    commit("setCookies", data);
  },
};
