<template>
  <div class="studies">
    <div v-for="study in studies" :key="study.id" class="study">
      <div class="study__content">
        <div>
          <img
            v-if="study.logotype_url"
            class="study__logo"
            :src="study.logotype_url"
            :alt="study.name"
          />
          <p v-else class="study__title">
            {{ study.name }}
          </p>
        </div>
        <p v-if="study.shoutout" class="study__description">
          {{ study.shoutout }}
        </p>
        <p class="study__description">
          {{ study.description ? study.description : $t("studies.no_description") }}
        </p>
      </div>
      <div class="study__action">
        <button class="study__buy" @click="goToBuyStudy(study.id)">
          {{ $t("studies.buy_new_study") }}
          <md-icon style="color: white">add</md-icon>
        </button>
        <span class="study__price"> {{ study.single_study_price }} {{ currency }} </span>
      </div>
      <div class="study__info">
        <a
          v-if="study.leaflet_coach"
          :href="study.leaflet_coach"
          target="_blank"
          class="study__button"
        >
          {{ $t("studies.leaflet_coach") }}
        </a>
        <a
          v-if="study.leaflet_participant"
          :href="study.leaflet_participant"
          target="_blank"
          class="study__button"
        >
          {{ $t("studies.leaflet_participant") }}
        </a>
        <a
          v-if="study.leaflet_report"
          :href="study.leaflet_report"
          target="_blank"
          class="study__button"
        >
          {{ $t("studies.leaflet_report") }}
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "Studies",
  data() {
    return {
      currency: "zł",
    };
  },
  computed: {
    ...mapState("studies", ["studies"]),
  },
  methods: {
    goToBuyStudy(studyId) {
      this.$parent.$emit("passUnclaimedStudy", studyId);
    },
  },
};
</script>
<style lang="scss" scoped>
.studies {
  margin: 24px auto;
  .study {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, auto-fit);
    background: #fff;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.14);
    border-radius: 6px;
    margin-bottom: 12px;
    padding: 20px;
    @media (min-width: 768px) {
      padding: 35px;
    }
    @media (min-width: 992px) {
      grid-template-columns: auto 150px;
      grid-template-rows: auto auto;
    }
    &__content {
      grid-column: 1 / 3;
      grid-row: 1 / 2;
      @media (min-width: 992px) {
        grid-column: 1 / 2;
        grid-row: 1 / 2;
      }
    }
    &__action {
      grid-column: 1 / 3;
      grid-row: 2 / 3;

      display: flex;
      flex-direction: column-reverse;
      gap: 16px;
      margin: 20px 0 28px;

      @media (min-width: 400px) {
        flex-direction: row;
      }
      @media (min-width: 992px) {
        grid-column: 2 / 3;
        grid-row: 1 / 3;

        flex-direction: column-reverse;
        justify-content: space-between;
        margin: 0;
        gap: 40px;
      }
    }
    &__info {
      grid-column: 1 / 3;
      grid-row: 3 / 4;

      display: flex;
      flex-direction: column;
      gap: 16px;

      @media (min-width: 992px) {
        grid-column: 1 / 2;
        grid-row: 2 / 3;
        align-items: end;

        flex-direction: row;
      }
    }
    &__logo {
      display: block;
      height: 80px;
      margin-bottom: 12px;
    }
    &__title {
      font-weight: 500;
      font-size: 24px;
      line-height: 18px;
      color: #1b3a68;
      margin-top: 5px;
      margin-bottom: 20px;
    }
    &__description {
      font-weight: 400;
      font-size: 16px;
      line-height: 28px;
      color: #1d2242;
      @media (min-width: 992px) {
        margin-bottom: 25px;
      }
    }
    &__buy {
      background: #62b866;
      border-radius: 6px;
      color: #fff;
      padding: 11px 14px;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      width: 100%;
    }
    &__price {
      height: 50px;
      background: rgba(232, 232, 232, 0.46);
      border-radius: 7px;
      font-weight: 500;
      font-size: 36px;
      line-height: 18px;
      color: #484848;
      padding: 11px 14px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &__button {
      cursor: pointer;
      background: #434f60;
      border-radius: 6px;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #fff;
      padding: 11px 14px;
      width: 100%;
      text-align: center;
      &:hover,
      &:focus,
      &:active {
        color: #fff !important;
      }

      @media (min-width: 992px) {
        width: auto;
      }
    }
  }
}
</style>
