<template>
  <div class="md-layout" ref="clockpicker">
    <label v-if="label" class="md-layout-item md-size-20 md-form-label" v-html="label"></label>
    <div class="md-layout-item flex">
      <md-field
        :class="[{ 'md-error md-focused': errors.has(title) }, { 'md-valid': !errors.has(title) }]"
      >
        <md-input
          :placeholder="placeholder"
          v-model="inputValue"
          :data-vv-name="title"
          type="text"
          v-validate="validation"
          :required="required"
          :disabled="disabled"
          readonly
        >
        </md-input>
        <HsValidatorStatus :field="veeFields[title]" />
      </md-field>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { TimepickerUI } from "timepicker-ui";
import HsValidatorStatus from "@/components/Forms/Partials/HsValidatorStatus";

export default {
  name: "HsClockPicker",
  inject: ["$validator"],
  components: {
    HsValidatorStatus,
  },
  data() {
    return {
      inputValue: "",
    };
  },
  props: {
    placeholder: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    value: {
      type: [String, Number],
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    validation: {
      type: Object,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    value(value) {
      this.inputValue = value;
    },
    inputValue(value) {
      this.$emit("input", value);
    },
  },
  created() {
    if (this.value) {
      this.inputValue = this.value;

      return;
    }
    this.getCurrentDate();
  },
  mounted() {
    const clockPickerConfig = {
      enableSwitchIcon: false,
      animation: false,
      clockType: "24h",
      cancelLabel: this.$t("global.cancel"),
      okLabel: this.$t("global.save"),
      timeLabel: this.label,
    };
    const clockPicker = new TimepickerUI(this.$refs.clockpicker, clockPickerConfig);
    clockPicker.create();

    this.$refs.clockpicker.addEventListener("accept", ({ detail }) => {
      this.inputValue = `${detail.hour}:${detail.minutes}`;
    });
  },
  methods: {
    getCurrentDate() {
      this.inputValue = moment(new Date()).format("HH:mm");
    },
  },
};
</script>

<style lang="scss">
.timepicker-ui-clock-hand,
.timepicker-ui-dot,
.timepicker-ui-clock-hand,
.timepicker-ui-circle-hand {
  @apply bg-blue-400;
}

.timepicker-ui-minutes.mobile.active,
.timepicker-ui-pm.mobile.active,
.timepicker-ui-ok.btn-mobile,
.timepicker-ui-keyboard-icon:hover,
.timepicker-ui-keyboard-icon.mobile:hover,
.timepicker-ui-cancel-btn,
.timepicker-ui-ok-btn,
.timepicker-ui-cancel-btn.mobile,
.timepicker-ui-ok.btn-mobile {
  @apply text-blue-400;
}

.timepicker-ui-hour:hover,
.timepicker-ui-hour.active,
.timepicker-ui-minutes:hover,
.timepicker-ui-minutes.active,
.timepicker-ui-hour.mobile:hover,
.timepicker-ui-hour.mobile.active,
.timepicker-ui-minutes.mobile:hover,
.timepicker-ui-minutes.mobile.active {
  @apply text-blue-400 bg-blue-200;
}

.timepicker-ui-ok-btn,
.timepicker-ui-ok-btn.mobile,
.timepicker-ui-cancel-btn,
.timepicker-ui-cancel-btn.mobile {
  width: auto;
  padding: 9px 6px;
}
</style>
