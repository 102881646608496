<template>
  <div class="px-3 lg:px-12 m-auto">
    <div class="w-full">
      <PurchasedStudies :is-loading="isCoachStudiesLoading" />
      <RandomizedBanner :banners="banners" />
      <DataTable
        :title="$route.meta.name"
        :is-loading="globalLoading"
        :columns="tableColumns"
        :table-data="exams"
        :pagination="pagination"
        :coach-studies="coachStudies"
        @page-change="changePage"
        @filter="filterData"
        @clear-filters="clearFilters"
        @update-filters="updateFilters"
      >
        <template slot="rowActions" slot-scope="{ item }">
          <div class="md-toolbar-row md-toolbar-section-end" v-if="item.study_id === smsStudyType">
            <md-button class="md-info" :to="{ name: 'SmsExam.SmsShow', params: { id: item.id } }">
              <md-icon>visibility</md-icon>
              <div class="hidden lg:block">{{ $t("exam.see") }}</div>
            </md-button>
            <md-menu md-size="small" :md-offset-x="-60" :md-offset-y="0">
              <md-button md-menu-trigger class="md-simple md-icon-button">
                <md-icon>more_vert</md-icon>
              </md-button>
              <md-menu-content>
                <md-menu-item
                  :md-disabled="!item.rounds[0].is_finished"
                  :class="{ disabled: !item.rounds[0].is_finished }"
                  :to="
                    item.rounds[0].is_finished
                      ? {
                          name: 'SmsExam.SmsResults',
                          params: { id: item.id, roundId: item.rounds[0].id },
                        }
                      : {}
                  "
                >
                  {{ $t("exam_round.see_results") }}
                </md-menu-item>
                <md-divider></md-divider>
                <md-menu-item class="md-list-item-router" @click="askForDelete(item)">
                  <a>{{ $t("exam.delete") }}</a>
                </md-menu-item>
              </md-menu-content>
            </md-menu>
          </div>
          <div v-else class="md-toolbar-row md-toolbar-section-end">
            <md-button class="md-info" :to="{ name: 'Exam.Show', params: { id: item.id } }">
              <md-icon>visibility</md-icon>
              <div class="hidden lg:block">{{ $t("exam.see") }}</div>
            </md-button>
            <md-menu md-align-trigger md-direction="bottom-end">
              <md-button md-menu-trigger class="md-simple md-icon-button">
                <md-icon>more_vert</md-icon>
              </md-button>
              <md-menu-content>
                <md-menu-item
                  :class="{ disabled: item.is_draft }"
                  :to="
                    !item.is_draft
                      ? {
                          name: 'Respondents.Additional',
                          params: {
                            id: lastExamRound(item.rounds).exam_id,
                            examRoundId: lastExamRound(item.rounds).id,
                          },
                        }
                      : {}
                  "
                  >{{ $t("exam_round.manage_respondents") }}</md-menu-item
                >
                <md-menu-item
                  :class="{ disabled: !isChangeDateActive(item) }"
                  :to="
                    isChangeDateActive(item)
                      ? {
                          name: 'Exam.Show',
                          params: { id: item.id, openExtendModal: true },
                        }
                      : {}
                  "
                  >{{ $t("exam_round.edit_end_date") }}</md-menu-item
                >
                <md-menu-item
                  v-if="hasExamRound(item.rounds)"
                  :class="{
                    disabled: !lastExamRound(item.rounds).is_finished,
                  }"
                  :to="
                    lastExamRound(item.rounds).is_finished
                      ? {
                          name: 'Exam.Results',
                          params: {
                            id: lastExamRound(item.rounds).exam_id,
                            roundId: lastExamRound(item.rounds).id,
                          },
                        }
                      : {}
                  "
                >
                  {{ $t("exam_round.see_results") }}
                </md-menu-item>
                <md-divider />
                <md-menu-item class="md-list-item-router" @click="askForDelete(item)">
                  <a>{{ $t("exam.delete") }}</a>
                </md-menu-item>
              </md-menu-content>
            </md-menu>
          </div>
        </template>
      </DataTable>

      <Alert v-if="!globalLoading">
        <template #title>
          {{ $t("dashboard.referal_alert_title") }}
        </template>
        <template #description>
          {{ $t("dashboard.referal_alert_description") }}
        </template>
      </Alert>
    </div>
  </div>
</template>
<script>
import { debounce } from "lodash";
import { deleteAlert } from "@/helpers/swal";
import { mapState, mapMutations, mapActions } from "vuex";
import DataTable from "@/components/Tables/DataTable";
import Alert from "@/components/Alert";
import PurchasedStudies from "@/pages/Dashboard/Partials/PurchasedStudies";
import RandomizedBanner from "@/pages/Dashboard/Partials/RandomizedBanner";
import i18n from "@/i18n";

const smsStudyId = 2;

export default {
  name: "DashboardIndex",
  components: {
    DataTable,
    Alert,
    PurchasedStudies,
    RandomizedBanner,
  },
  data() {
    return {
      isCoachStudiesLoading: false,
      globalLoading: true,
      currentPage: 1,
      tableColumns: [
        {
          title: this.$t("participant.name"),
          calculatedValue(item) {
            return item.participant
              ? `${item.participant.first_name} ${item.participant.last_name}`
              : "";
          },
          filterable: true,
          name: "participant.name",
        },
        {
          title: this.$t("participant.company"),
          calculatedValue(item) {
            return item.participant ? item.participant.company : "";
          },
          filterable: true,
          name: "participant.company",
        },
        {
          title: this.$t("exam.name"),
          calculatedValue(item) {
            return item.study.name;
          },
          filterable: true,
          name: "study.name",
        },
        {
          title: this.$t("exam.status"),
          calculatedValue(item) {
            if (item.rounds.length) {
              return item.rounds[item.rounds.length - 1].status;
            } else {
              return i18n.t("exam.statuses.draft");
            }
          },
          filterable: true,
          name: "rounds.status",
        },
        {
          title: this.$t("studyType.type"),
          calculatedValue(item) {
            if (item.rounds.length) {
              return item.rounds.reduce((prev, round) => {
                const { study_type, number } = round;
                const studyName =
                  study_type && number === 1
                    ? i18n.t("studyType.study_type_first_round")
                    : i18n.t("studyType.study_type_second_round");
                return prev + (study_type ? studyName + "<br />" : "");
              }, "");
            } else {
              return "-";
            }
          },
        },
        {
          title: this.$t("exam.end_date"),
          calculatedValue(item) {
            if (item.rounds.length) {
              return item.rounds.reduce((prev, round) => {
                return prev + round.end_date + "<br />";
              }, "");
            } else {
              return "-";
            }
          },
        },
        {
          title: this.$t("exam.progress"),
          calculatedValue(item) {
            if (item.study_id === smsStudyId) {
              if (item.rounds.length) {
                if (item.rounds[0].status !== "Badanie anulowano") {
                  const responseList = item.rounds[0]?.responses_list.slice(-1)[0]?.response;
                  const hasSmsResponses = !!item.rounds[0]?.responses_list.length;
                  return `${item.rounds[0].responses_counts.SUMA}/${
                    item.rounds[0].responses_counts.POWINNO
                  }
                  <div class="hidden lg:block md-progress-bar md-determinate md-green">
                      <div class="md-progress-bar-fill" style="width: ${
                        (item.rounds[0].responses_counts.SUMA /
                          item.rounds[0].responses_counts.POWINNO) *
                        100
                      }%;"></div>
                  </div>
                  ${
                    hasSmsResponses
                      ? `<div class="mb-1">Ostatnia: ${
                          responseList ? responseList : "Brak odpowiedzi"
                        }</div>`
                      : ""
                  }`;
                } else {
                  return `
                  <div class="hidden lg:block md-progress-bar md-determinate md-warning">
                      <div class="md-progress-bar-fill" style="width: ${(1 / 1) * 100}%;"></div>
                  </div>`;
                }
              }
              return "";
            } else {
              const rounds = item.rounds;
              if (rounds.length) {
                return rounds.reduce((prev, round) => {
                  if (round.fulfilled_respondents / round.total_respondents == 1) {
                    return `${prev} ${round.fulfilled_respondents}/${round.total_respondents}
                    <div class="hidden lg:block md-progress-bar md-determinate md-green">
                        <div class="md-progress-bar-fill" style="width: ${
                          (round.fulfilled_respondents / round.total_respondents) * 100
                        }%;"></div>
                    </div>`;
                  } else if (round.status === "Badanie anulowano") {
                    return `
                      <div class="hidden lg:block md-progress-bar md-determinate md-warning">
                          <div class="md-progress-bar-fill" style="width: ${(1 / 1) * 100}%;"></div>
                      </div>`;
                  } else {
                    return `${prev} ${round.fulfilled_respondents}/${round.total_respondents}
                    <div class="hidden lg:block md-progress-bar md-determinate md-theme-default">
                        <div class="md-progress-bar-fill" style="width: ${
                          (round.fulfilled_respondents / round.total_respondents) * 100
                        }%;"></div>
                    </div>`;
                  }
                }, "");
              }
              return "";
            }
          },
        },
      ],
      filters: {},
      filterMethod: null,
    };
  },
  computed: {
    ...mapState("exam", ["exams", "pagination"]),
    ...mapState("coachStudies", ["coachStudies"]),
    ...mapState("banner", ["banners"]),

    smsStudyType() {
      return smsStudyId;
    },
  },
  methods: {
    ...mapActions("exam", ["getAllExams", "deleteExam"]),
    ...mapActions("smsExam", ["deleteSmsExam"]),
    ...mapActions("coachStudies", ["getCoachStudies"]),
    ...mapActions("banner", ["getBanners"]),
    ...mapMutations("layout", ["startLoading", "finishLoading"]),

    isChangeDateActive({ rounds }) {
      const examStatus = rounds.length
        ? rounds[rounds.length - 1].status
        : i18n.t("exam.statuses.draft");
      const availableStatuses = ["Nierozpoczęte", "W trakcie"];

      return availableStatuses.includes(examStatus);
    },
    isResultsActive({ rounds }) {
      const examStatus = rounds.length
        ? rounds[rounds.length - 1].status
        : i18n.t("exam.statuses.draft");
      const availableStatuses = ["Zakończono badanie", "Raport gotowy"];

      return availableStatuses.includes(examStatus);
    },
    hasExamRound(data) {
      return !!data.length;
    },
    lastExamRound(data) {
      if (!data.length) {
        return {};
      }

      return data[data.length - 1];
    },
    async getData(params = {}) {
      const defaultParams = {
        page: this.currentPage,
        filters: this.filters,
        limit: 10,
      };
      const payload = Object.assign(defaultParams, params);
      await this.getAllExams(payload);
    },
    async changePage(value) {
      this.globalLoading = true;
      this.currentPage = value;
      await this.getData();
      this.globalLoading = false;
    },
    async askForDelete(exam) {
      const { dismiss } = await deleteAlert({
        title: this.$t("exam.delete_confirmation", {
          firstName: exam.participant.first_name,
          lastName: exam.participant.last_name,
        }),
      });
      if (!dismiss) {
        if (exam.study_id === this.smsStudyType) {
          await this.deleteSmsExam(exam.id);
        } else {
          await this.deleteExam(exam.id);
        }
        this.isCoachStudiesLoading = true;
        await this.getData();
        await this.getCoachStudies();
        this.isCoachStudiesLoading = false;
      }
    },
    async updateFilters(data) {
      this.filters = data;
      await this.filterMethod();
    },
    async filterData(data) {
      let [key, value] = data.split(":");
      if (value.length > 0) {
        this.filters[key] = value;
      } else {
        this.filters[key] = undefined;
      }
      await this.filterMethod();
    },
    async clearFilters() {
      this.filters = {};
      await this.getData();
    },
  },
  async created() {
    this.startLoading();
    this.globalLoading = true;
    if (window.innerWidth < 992) {
      this.tableColumns = this.tableColumns.slice(0, 1).concat(this.tableColumns.slice(-1));
    }
    await this.getBanners();
    await this.getData();
    this.$store.commit("participant/clearParticipant");
    this.$store.commit("studies/clearStudy");
    this.globalLoading = false;
    this.finishLoading();

    this.filterMethod = debounce(async () => {
      this.globalLoading = true;
      await this.getData();
      this.globalLoading = false;
    }, 500);
  },
};
</script>
<style>
.disabled > a,
.disabled > a > div {
  cursor: not-allowed !important;
}
.disabled,
.disabled > a:hover {
  background-color: #e0e0e0 !important;
}
.disabled .router-link-active > div {
  color: #000000 !important;
}
</style>
