<template>
  <form @submit.prevent="submitForm">
    <div class="max-w-2xl mx-auto">
      <div class="md-card">
        <md-card-header class="md-card-header-text md-card-header-blue">
          <div class="card-text">
            <h4 class="title">{{ $t("survey.step2.title.create") }}</h4>
          </div>
        </md-card-header>
        <div class="md-card-content">
          <div class="pb-8">
            <Uploader v-if="!attachment" v-model="formData.file" />
            <div v-if="attachment" class="logo-attachment">
              <p class="logo-attachment__title">Logo</p>
              <div class="logo-attachment__logo">
                <div class="logo-attachment__icon">
                  <img :src="examCustomisation.exam_customisation_attachment?.url" alt="file" />
                </div>
                <button
                  class="logo-attachment__remove"
                  type="button"
                  @click="deleteAttachment"
                  :title="$t('global.remove')"
                >
                  {{ $t("global.remove") }}
                </button>
              </div>
            </div>
            <HsTextInput
              v-model="formData.companyName"
              :label="$t('survey.step2.company_name')"
              title="company_name"
              :validation="{
                required: true,
              }"
              ><span v-if="errors.has('company_name')" class="form-validate"
                >{{ errors.first("company_name") }}
              </span>
            </HsTextInput>
            <HsTextInput
              v-model="formData.phoneNumber"
              :label="$t('survey.step2.phone_number')"
              title="phone_number"
              :validation="{
                required: true,
              }"
              ><span v-if="errors.has('phone_number')" class="form-validate"
                >{{ errors.first("phone_number") }}
              </span>
            </HsTextInput>
          </div>
        </div>
      </div>
    </div>
    <div class="max-w-2xl mx-auto">
      <md-button class="md-raised md-rose" @click="goBack">
        {{ $t("global.back") }}
      </md-button>
      <md-button type="submit" class="md-raised md-info float-right"
        >{{ $t("survey.next") }}
      </md-button>
    </div>
  </form>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { deleteAlert } from "@/helpers/swal";
import HsTextInput from "@/components/Forms/Inputs/HsTextInput";
import Uploader from "@/components/Uploader/Uploader";

export default {
  name: "ExamCustomisationEdit",
  components: {
    HsTextInput,
    Uploader,
  },
  data() {
    return {
      formData: {
        companyName: "",
        phoneNumber: "",
        file: null,
      },
    };
  },
  computed: {
    ...mapState("exam", ["exam"]),
    ...mapState("examCustomisations", ["examCustomisation"]),
    attachment() {
      return this.examCustomisation.exam_customisation_attachment
        ? this.examCustomisation.exam_customisation_attachment?.url
        : false;
    },
  },
  async created() {
    this.$store.commit("layout/startLoading");
    await this.getExam({ id: this.$route.params.id });
    await Promise.all([
      this.getExamCustomisation({ id: this.$route.params.id }),
      this.getStudy(this.exam.study_id),
      this.getParticipant({ id: this.exam.participant_id }),
    ]);
    this.formData.companyName = this.examCustomisation.company_name;
    this.formData.phoneNumber = this.examCustomisation.phone_number;
    this.$store.commit("layout/finishLoading");
  },
  methods: {
    ...mapActions("examCustomisations", ["getExamCustomisation", "updateExamCustomisation"]),
    ...mapActions("exam", ["getExam"]),
    ...mapActions("examCustomisationAttachments", [
      "createExamCustomisationAttachment",
      "deleteExamCustomisationAttachment",
    ]),
    ...mapActions("studies", ["getStudy"]),
    ...mapActions("participant", ["getParticipant"]),
    async submit() {
      await this.updateExamCustomisation({
        examCustomisationId: this.examCustomisation.id,
        payload: this.formData,
      });
      if (this.formData.file) {
        const formData = new FormData();
        formData.append("exam_customisation_id", this.examCustomisation.id);
        formData.append("attachment", this.formData.file);
        await this.createExamCustomisationAttachment(formData);
      }
      this.$router.push({
        name: "Respondents.Create",
        params: { id: this.exam.id },
      });
    },
    async submitForm() {
      const result = await this.$validator.validateAll();

      if (result) {
        this.submit();
      }
    },
    async deleteAttachment() {
      await this.deleteExamCustomisationAttachment(this.examCustomisation.id);
      await this.getExamCustomisation({ id: this.$route.params.id });
    },
    async goBack() {
      const { dismiss } = await deleteAlert({
        title: this.$t("respondents.confirm.title"),
        text: this.$t("respondents.confirm.text"),
        confirmButtonText: this.$t("respondents.confirm.confirmButtonText"),
      });
      if (!dismiss) {
        this.$router.push({
          name: "Exam.Edit",
          params: { id: this.$route.params.id },
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.form-validate {
  position: absolute;
  font-size: 12px;
  color: #d9534f;
}
.logo-attachment {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 300px;
  padding: 20px 18px 14px;
  margin: 25px auto 0;
  border: 1px solid #27569a;
  border-radius: 4px;
  &__title {
    position: absolute;
    color: #aaa;
    top: 10px;
    left: 16px;
  }
  &__icon {
    width: 42px;
    height: 42px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      pointer-events: none;
    }
  }
  &__remove {
    text-align: center;
    width: 100%;
    margin-top: 6px;
    font-size: 12px;
    font-weight: 600;
  }
}
</style>
