import api from "@/helpers/api";

export default {
  async getExamMedia({ commit }, payload) {
    const {
      data: { data },
    } = await api.get(`coach/exam-media`, { params: payload });

    commit("setExamMedia", data.data);
    commit("setPagination", data);
  },
  async createExamMedia(store, payload) {
    await api.post("/coach/exam-media", payload);
  },
  async deleteExamMedia(store, id) {
    await api.delete(`/coach/exam-media/${id}`);
  },
  async sendExamMedia(store, { mediaId, participantId }) {
    await api.get(`/coach/exam-media-emails/${mediaId}/${participantId}`);
  },
};
