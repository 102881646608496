export default {
  setSmsExam(state, data) {
    state.smsExam = data;
  },
  clearSmsExam(state) {
    state.smsExam = {};
  },
  setSmsExams(state, data) {
    state.smsExams = data;
  },
  setPagination(state, { from, to, total, per_page, current_page }) {
    state.pagination = {
      from: from,
      to: to,
      total: total,
      per_page: per_page,
      current_page: current_page,
    };
  },
  setResult(state, data) {
    state.results = data;
  },
};
