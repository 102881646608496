import DashboardLayout from "@/pages/Dashboard/Layout/DashboardLayout.vue";
import UnauthLayout from "@/pages/Dashboard/Layout/UnauthLayout.vue";

// Dashboard pages
import WelcomePage from "@/pages/Dashboard/WelcomePage.vue";
import Dashboard from "@/pages/Dashboard/Dashboard.vue";
import Faq from "@/pages/Dashboard/Faq.vue";
import AvailableStudies from "@/pages/Study/AvailableStudies.vue";

// Pages
import authPages from "./auth";
import profilePages from "./coach";
import examPages from "./exam";
import respondentsPages from "./respondents";
import smsConfigurationPages from "./smsConfiguration";
import examCustomisationPages from "./examCustomisation";
import orderPages from "./order";

// i18n
import i18n from "../i18n";

// unauth
import PolicyPage from "@/pages/Dashboard/PolicyPage.vue";
import RulesPage from "@/pages/Dashboard/RulesPage.vue";
import CookiesPage from "@/pages/Dashboard/CookiesPage.vue";

const routes = [
  {
    path: "/",
    redirect: "/welcome",
    name: "Home",
  },
  {
    path: "*",
    redirect: "/welcome",
    name: "Start",
  },
  authPages,
  examPages,
  respondentsPages,
  smsConfigurationPages,
  profilePages,
  examCustomisationPages,
  orderPages,
  {
    path: "/",
    component: DashboardLayout,
    children: [
      {
        path: "dashboard",
        name: "Dashboard",
        component: Dashboard,
        meta: {
          name: i18n.t("dashboard.dashboard"),
          breadcrumbs: [
            {
              name: i18n.t("dashboard.dashboard"),
              link: "Dashboard",
            },
          ],
        },
      },
      {
        path: "faq",
        name: "Faq",
        component: Faq,
        meta: {
          name: i18n.t("support.faq"),
          breadcrumbs: [
            {
              name: i18n.t("dashboard.dashboard"),
              link: "Dashboard",
            },
            {
              name: i18n.t("support.faq"),
              link: "Faq",
            },
          ],
        },
      },
      {
        path: "available-studies",
        name: "AvailableStudies",
        component: AvailableStudies,
        meta: {
          name: i18n.t("studies.available_studies"),
        },
      },
      {
        path: "welcome",
        name: "WelcomePage",
        component: WelcomePage,
        meta: {
          name: i18n.t("dashboard.welcome"),
        },
      },
    ],
  },
  {
    path: "/documents",
    component: UnauthLayout,
    name: "Unauth",
    children: [
      {
        path: "policy",
        name: "Unauth.PolicyPage",
        component: PolicyPage,
      },
      {
        path: "rules",
        name: "Unauth.RulesPage",
        component: RulesPage,
      },
      {
        path: "cookies",
        name: "Unauth.CookiesPage",
        component: CookiesPage,
      },
    ],
  },
];

export default routes;
