<template>
  <div v-if="!isLoading" class="available-studies px-6 pt-6 pb-16 m-auto">
    <h3 class="font-bold">
      {{ $t("studies.available_studies") }}
    </h3>
    <div>
      <StudiesList />
      <Alert>
        <template #title>
          {{ $t("dashboard.referal_alert_title") }}
        </template>
        <template #description>
          {{ $t("dashboard.referal_alert_description") }}
        </template>
      </Alert>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState, mapMutations } from "vuex";
import StudiesList from "@/pages/Study/Partials/StudiesList";
import Alert from "@/components/Alert";

export default {
  name: "AvailableStudies",
  components: {
    StudiesList,
    Alert,
  },
  computed: {
    ...mapState("layout", ["isLoading"]),
  },
  async created() {
    try {
      this.startLoading();
      await this.getStudies();
    } catch (e) {
      console.error(e);
    } finally {
      this.finishLoading();
    }
  },
  methods: {
    ...mapActions("studies", ["getStudies"]),
    ...mapMutations("layout", ["startLoading", "finishLoading"]),
  },
};
</script>

<style lang="scss" scoped>
.available-studies {
  max-width: 1400px;
  margin: 0 auto;
}
</style>
