<template>
  <div class="preloader" :class="{ 'preloader--small': small }">
    <svg class="preloader__loader" viewBox="25 25 50 50">
      <circle
        class="preloader__path"
        cx="50"
        cy="50"
        r="20"
        fill="none"
        stroke-width="3"
        stroke-miterlimit="10"
        :style="colorProps"
      />
    </svg>
  </div>
</template>
<script>
export default {
  name: "Loader",
  props: {
    color: {
      type: String,
      default: "white",
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    colorProps() {
      return {
        "--strokeColor": this.color,
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.preloader {
  position: relative;
  height: 36px;
  width: 36px;
  &--small {
    height: 20px;
    width: 20px;
  }
  &__loader {
    width: 100%;
    height: 100%;
    animation: rotate 2s linear infinite;
    transform-origin: center center;
  }
  &__path {
    stroke-linecap: round;
    stroke: var(--strokeColor);
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    animation: dash 1.5s ease-in-out infinite;
  }
}
@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}
</style>
