<template>
  <form @submit.prevent="submitForm" class="max-w-4xl mx-auto">
    <div class="md-card">
      <md-card-header class="md-card-header-text md-card-header-blue">
        <div class="card-text">
          <h4 class="title">{{ formTitle }}</h4>
        </div>
      </md-card-header>
      <div class="md-card-content">
        <HsTextInput
          v-model="formData.firstName"
          :label="$t('registration.first_name')"
          title="first_name"
          :validation="{
            required: true,
          }"
        >
          <span v-if="errors.has('first_name')" class="form-validate"
            >{{ errors.first("first_name") }}
          </span>
        </HsTextInput>
        <HsTextInput
          v-model="formData.lastName"
          :label="$t('registration.last_name')"
          title="last_name"
          :validation="{
            required: true,
          }"
        >
          <span v-if="errors.has('last_name')" class="form-validate"
            >{{ errors.first("last_name") }}
          </span>
        </HsTextInput>
        <HsTextInput
          v-model="formData.email"
          :label="$t('registration.email')"
          title="email"
          :validation="{
            required: true,
            email: true,
          }"
        >
          <span v-if="errors.has('email')" class="form-validate">{{ errors.first("email") }} </span>
        </HsTextInput>
        <HsTextInput
          v-model="formData.phoneNumber"
          :label="$t('registration.phone_number')"
          title="phone_number"
          :validation="{
            required: true,
          }"
        >
          <span v-if="errors.has('phone_number')" class="form-validate"
            >{{ errors.first("phone_number") }}
          </span>
        </HsTextInput>
        <HsTextInput
          v-model="formData.nipNumber"
          :label="$t('registration.nip_number')"
          title="nip_number"
          :validation="{
            required: true,
          }"
        >
          <span v-if="errors.has('nip_number')" class="form-validate"
            >{{ errors.first("nip_number") }}
          </span>
        </HsTextInput>
        <HsTextInput
          v-model="formData.companyName"
          :label="$t('registration.company_name')"
          title="company_name"
          :validation="{
            required: true,
          }"
        >
          <span v-if="errors.has('company_name')" class="form-validate"
            >{{ errors.first("company_name") }}
          </span>
        </HsTextInput>
        <HsTextInput
          v-model="formData.address"
          :label="$t('registration.address')"
          title="address"
          :validation="{
            required: true,
          }"
        >
          <span v-if="errors.has('address')" class="form-validate"
            >{{ errors.first("address") }}
          </span>
        </HsTextInput>
        <HsTextInput
          v-model="formData.postalCode"
          :label="$t('registration.postal_code')"
          title="postal_code"
          :validation="{
            required: true,
          }"
        >
          <span v-if="errors.has('postal_code')" class="form-validate"
            >{{ errors.first("postal_code") }}
          </span>
        </HsTextInput>
        <HsTextInput
          v-model="formData.city"
          :label="$t('registration.city')"
          title="city"
          :validation="{
            required: true,
          }"
        >
          <span v-if="errors.has('city')" class="form-validate">{{ errors.first("city") }} </span>
        </HsTextInput>
        <HsTextInput
          v-model="formData.referal_data"
          :label="$t('registration.referal_data')"
          title="referal_data"
          :disabled="coachProfile.referal_data"
        >
          <span v-if="errors.has('referal_data')" class="form-validate"
            >{{ errors.first("referal_data") }}
          </span>
        </HsTextInput>
      </div>
    </div>
    <md-button class="md-rose" @click="$emit('cancel')">
      {{ $t("global.back") }}
    </md-button>
    <md-button class="md-info float-right" type="submit">
      {{ $t("global.save_changes") }}
    </md-button>
  </form>
</template>
<script>
import { mapState } from "vuex";
import HsTextInput from "@/components/Forms/Inputs/HsTextInput";

export default {
  name: "EditProfileForm",
  props: {
    formTitle: {
      type: String,
      required: true,
    },
  },
  components: {
    HsTextInput,
  },
  data() {
    return {
      previousEmail: "",
      formData: {
        id: "",
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        nipNumber: "",
        companyName: "",
        address: "",
        postalCode: "",
        city: "",
        referal_data: "",
        emailChanged: false,
      },
    };
  },
  computed: {
    ...mapState("coachProfile", ["coachProfile"]),
  },
  watch: {
    "formData.email": {
      handler(value) {
        if (value !== this.previousEmail && this.previousEmail) {
          this.formData.emailChanged = true;
        } else {
          this.formData.emailChanged = false;
        }
      },
      deep: true,
    },
  },
  created() {
    this.previousEmail = this.coachProfile.email;
    this.formData.id = this.coachProfile.id;
    this.formData.firstName = this.coachProfile.first_name;
    this.formData.lastName = this.coachProfile.last_name;
    this.formData.email = this.coachProfile.email;
    this.formData.phoneNumber = this.coachProfile.coach_profile.phone_number;
    this.formData.nipNumber = this.coachProfile.coach_profile.nip_number;
    this.formData.companyName = this.coachProfile.coach_profile.company_name;
    this.formData.address = this.coachProfile.coach_profile.address;
    this.formData.postalCode = this.coachProfile.coach_profile.postal_code;
    this.formData.city = this.coachProfile.coach_profile.city;
    this.formData.referal_data = this.coachProfile.referal_data;
  },
  methods: {
    async submit() {
      this.$emit("form-submit", this.formData);
    },
    async submitForm() {
      const result = await this.$validator.validateAll();

      if (result) {
        this.submit();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.form-validate {
  position: absolute;
  font-size: 12px;
  color: #d9534f;
}
</style>
