import AuthLayout from "@/pages/Dashboard/Layout/AuthLayout";

const Login = () => import(/* webpackChunkName: "auth" */ "@/pages/Auth/Login");
const ForgetPassword = () => import(/* webpackChunkName: "auth" */ "@/pages/Auth/ForgetPassword");
const ResetPassword = () => import(/* webpackChunkName: "auth" */ "@/pages/Auth/ResetPassword");
const Register = () => import(/* webpackChunkName: "auth" */ "@/pages/Auth/Register");
const CompleteRegister = () =>
  import(/* webpackChunkName: "auth" */ "@/pages/Auth/CompleteRegister");
import i18n from "../i18n";

export default {
  path: "/auth",
  component: AuthLayout,
  name: "Authentication",
  children: [
    {
      path: "login",
      name: "Auth.Login",
      component: Login,
      meta: {
        name: i18n.t("auth.login"),
      },
    },
    {
      path: "register",
      name: "Auth.Register",
      component: Register,
      meta: {
        name: i18n.t("auth.registration"),
      },
    },
    {
      path: "complete-register/:token",
      name: "Auth.CompleteRegister",
      component: CompleteRegister,
      meta: {
        name: i18n.t("auth.complete_registration"),
      },
    },
    {
      path: "forget",
      name: "Auth.ForgetPassword",
      component: ForgetPassword,
      meta: {
        name: i18n.t("auth.forget"),
      },
    },
    {
      path: "reset/:token",
      name: "Auth.ResetPassword",
      component: ResetPassword,
      meta: {
        name: i18n.t("auth.reset"),
      },
    },
  ],
};
