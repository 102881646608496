export default {
  buy_study: "Dokup narzędzie",
  purchased_title: "Obecnie posiadasz wykupione narzędzia:",
  order: {
    studies_purchase: "Wykupienie badań",
    purchase: "Zakup",
    cost: "Koszt",
    summary: "Podsumowanie",
    cancel: "zrezygnuj",
    go_to_summary: "przejdź do podsumowania",
    order_summary: "Podsumowanie zamówienia",
    invoice_data: "Dane do faktury",
    nip_number: "NIP",
    business_or_company_name: "Nazwa działalności/firmy",
    billing_address: "Adres siedziby do faktury",
    city: "Miejscowość",
    postal_code: "Kod pocztowy",
    total_to_be_paid: "Razem do zapłaty",
    return_to_cart: "wróć do edytowania koszyka",
    go_to_payment: "przejdź do płatności",
    study_type: "Rodzaj badania",
    currently_have: "Obecnia posiadasz",
    study_price: "Cena badania",
    title: {
      show: "Twoje zamówienie",
    },
    thank_you: "Dziękujemy za zamówienie",
    download_payment_confirmation: "POBIERZ POTWIERDZENIE PŁATNOŚCI",
    back_to_home: "Wróć do ekranu głównego",
    processing_error: "Bląd realizacji zamówienia",
    pay_again: "Zapłać ponownie",
    payment_success_text:
      "Na swoją skrzynkę mailową otrzymasz wkrótce fakturę za zakup, a w platformie już możesz rozpocząć przeprowadzanie badania.",
    payment_failed_text:
      "Przepraszamy, ale wystąpił błąd związany z płatnością. Spróbuj ponownie lub przejdź z powrotem do platformy.",
    payment_url_generation_alert:
      "Wystąpił problem z ponowieniem płatności. Jeżeli masz problemy techniczne skontaktuj się z nami.",
    min_order_quantity_error: "Minimalna liczba sztuk tego badania to {minOrderQuantity}",
  },
  available_studies: "Dostępne narzędzia na platformie NTC",
  no_description: "Brak opisu",
  buy_new_study: "Kup narzędzie",
  leaflet_coach: "Ulotka dla trenera",
  leaflet_participant: "Ulotka dla uczestnika",
  leaflet_report: "Przykładowy raport",
  check_other_study: "Czy skorzystałeś już z badania",
  click_to_buy: "Możesz wykupić je tutaj",
};
