import DashboardLayout from "@/pages/Dashboard/Layout/DashboardLayout";

import Create from "@/pages/Exam/ExamCustomisation/Create";
import Edit from "@/pages/Exam/ExamCustomisation/Edit";

import i18n from "../i18n";

export default {
  path: "/exam-customisation",
  component: DashboardLayout,
  redirect: "/exam-customisation/create",
  name: "ExamCustomisation",
  children: [
    {
      path: "create/:id",
      name: "ExamCustomisation.Create",
      component: Create,
      meta: {
        name: i18n.t("survey.step3.title.create"),
        breadcrumbs: [
          {
            name: i18n.t("dashboard.dashboard"),
            link: "Dashboard",
          },
          {
            nameGetter: "studies/getStudyName",
            disabled: true,
          },
          {
            nameGetter: "participant/getParticipantName",
            disabled: true,
          },
          {
            name: i18n.t("survey.step2.title.create"),
            link: "ExamCustomisation.Create",
          },
        ],
      },
    },
    {
      path: "exam-customisation/:id/edit",
      name: "ExamCustomisation.Edit",
      component: Edit,
      meta: {
        name: i18n.t("survey.step1.title.edit"),
        breadcrumbs: [
          {
            name: i18n.t("dashboard.dashboard"),
            link: "Dashboard",
          },
          {
            nameGetter: "studies/getStudyName",
            disabled: true,
          },
          {
            nameGetter: "participant/getParticipantName",
            disabled: true,
          },
          {
            name: i18n.t("survey.step2.title.edit"),
            link: "ExamCustomisation.Create",
          },
        ],
      },
    },
  ],
};
