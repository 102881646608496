<template>
  <form @submit.prevent="submitForm">
    <div class="max-w-2xl mx-auto">
      <div class="md-card">
        <md-card-header class="md-card-header-text md-card-header-blue">
          <div class="card-text">
            <h4 class="title">{{ $t("survey.step2.title.create") }}</h4>
          </div>
        </md-card-header>
        <div class="md-card-content">
          <Uploader v-model="formData.file" />
          <HsTextInput
            v-model="formData.companyName"
            :label="$t('survey.step2.company_name')"
            title="company_name"
            :validation="{
              required: true,
            }"
            ><span v-if="errors.has('company_name')" class="form-validate"
              >{{ errors.first("company_name") }}
            </span>
          </HsTextInput>
          <HsTextInput
            v-model="formData.phoneNumber"
            :label="$t('survey.step2.phone_number')"
            title="phone_number"
            :validation="{
              required: true,
            }"
            ><span v-if="errors.has('phone_number')" class="form-validate"
              >{{ errors.first("phone_number") }}
            </span>
          </HsTextInput>
        </div>
      </div>
    </div>
    <div class="max-w-2xl mx-auto">
      <md-button class="md-raised md-rose" @click="goBack">
        {{ $t("global.back") }}
      </md-button>
      <md-button type="submit" class="md-raised md-info float-right"
        >{{ $t("survey.next") }}
      </md-button>
    </div>
  </form>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { deleteAlert } from "@/helpers/swal";
import HsTextInput from "@/components/Forms/Inputs/HsTextInput";
import Uploader from "@/components/Uploader/Uploader";

export default {
  name: "ExamCustomisationCreate",
  components: {
    HsTextInput,
    Uploader,
  },
  data() {
    return {
      formData: {
        companyName: "",
        phoneNumber: "",
        file: null,
      },
    };
  },
  computed: {
    ...mapState("exam", ["exam"]),
    ...mapState("coachProfile", ["coachProfile"]),
    ...mapState("examCustomisations", ["examCustomisation"]),
  },
  async created() {
    this.$store.commit("layout/startLoading");
    await Promise.all([this.getExam({ id: this.$route.params.id })]);
    await Promise.all([
      this.getCoachProfile({ coachId: this.exam.coach_id }),
      this.getStudy(this.exam.study_id),
      this.getParticipant({ id: this.exam.participant_id }),
      this.getExamCustomisation({ id: this.$route.params.id }),
    ]);
    this.formData.companyName = this.coachProfile.coach_profile.company_name;
    this.formData.phoneNumber = this.coachProfile.coach_profile.phone_number;
    if (this.examCustomisation.length != 0) {
      this.formData.companyName = this.examCustomisation.company_name;
      this.formData.phoneNumber = this.examCustomisation.phone_number;
    }
    this.$store.commit("layout/finishLoading");
  },
  methods: {
    ...mapActions("examCustomisations", ["createExamCustomisation", "getExamCustomisation"]),
    ...mapActions("exam", ["getExam"]),
    ...mapActions("examCustomisationAttachments", ["createExamCustomisationAttachment"]),
    ...mapActions("coachProfile", ["getCoachProfile"]),
    ...mapActions("studies", ["getStudy"]),
    ...mapActions("participant", ["getParticipant"]),

    async submit() {
      const createdCustomisation = await this.createExamCustomisation({
        examId: this.exam.id,
        ...this.formData,
      });
      if (this.formData.file) {
        const formData = new FormData();
        formData.append("exam_customisation_id", createdCustomisation.id);
        formData.append("attachment", this.formData.file);
        await this.createExamCustomisationAttachment(formData);
      }
      this.$router.push({
        name: "Respondents.Create",
        params: { id: this.exam.id },
      });
    },
    async submitForm() {
      const result = await this.$validator.validateAll();
      if (result) {
        this.submit();
      }
    },
    async goBack() {
      const { dismiss } = await deleteAlert({
        title: this.$t("examCustomisation.back.title"),
        text: this.$t("examCustomisation.back.text"),
        confirmButtonText: this.$t("examCustomisation.back.confirmButtonText"),
      });

      if (!dismiss) {
        this.$router.push({
          name: "Exam.Edit",
          params: { id: this.$route.params.id },
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.form-validate {
  position: absolute;
  font-size: 12px;
  color: #d9534f;
}
</style>
