import auth from "./auth";
import exam from "./exam";
import smsExam from "./sms-exam";
import smsExamConfiguration from "./sms-exam-configuration";
import participant from "./participant";
import respondent from "./respondent";
import section from "./section";
import examRound from "./exam-round";
import participantGoals from "./participant-goals";
import participantNotes from "./participant-notes";
import participantNotifications from "./participant-notitifations";
import coachStudies from "./coach-study";
import coachProfile from "./coach-profile";
import studies from "./studies";
import examRoundComments from "./exam-round-comments";
import layout from "./layout";
import studyRespondGroups from "./study-respond-groups";
import participantNoteAttachments from "./participant-note-attachments";
import examCustomisations from "./exam-customisations";
import examCustomisationAttachments from "./exam-customisation-attachments";
import order from "./order";
import goalQuestions from "./goal-questions";
import supportTicket from "./support-ticket";
import examMedia from "./exam-media";
import faq from "./faq";
import textPages from "./text-pages";
import banner from "./banner";

export default {
  auth,
  exam,
  smsExam,
  smsExamConfiguration,
  participant,
  respondent,
  section,
  examRound,
  participantGoals,
  participantNotes,
  participantNotifications,
  examRoundComments,
  layout,
  coachStudies,
  coachProfile,
  studies,
  studyRespondGroups,
  participantNoteAttachments,
  examCustomisations,
  examCustomisationAttachments,
  order,
  goalQuestions,
  supportTicket,
  examMedia,
  faq,
  textPages,
  banner,
};
