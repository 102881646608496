<template>
  <div v-if="randomizedBanner" class="card text-center mt-8">
    <div class="card-header">
      <a :href="randomizedBanner.url" target="_blank">
        <img class="card-img-top" :src="randomizedBanner.storage_url" alt="" />
      </a>
    </div>
  </div>
</template>
<script>
export default {
  name: "RandomizedBanner",
  props: {
    banners: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    randomizedBanner() {
      return this.banners[Math.floor(Math.random() * this.banners.length)];
    },
  },
};
</script>
