import DashboardLayout from "@/pages/Dashboard/Layout/DashboardLayout";
import Create from "@/pages/SmsConfiguration/Create";
import Edit from "@/pages/SmsConfiguration/Edit";
import i18n from "../i18n";

export default {
  path: "/sms-configuration",
  component: DashboardLayout,
  redirect: "/sms-configuration/create",
  name: "SmsConfiguration",
  children: [
    {
      path: "create/:id",
      name: "SmsConfiguration.Create",
      component: Create,
      meta: {
        name: i18n.t("survey.step3.title.create"),
        breadcrumbs: [
          {
            name: i18n.t("dashboard.dashboard"),
            link: "Dashboard",
          },
          {
            name: "Formularz badania",
            link: "SmsExam.SmsEdit",
          },
          {
            name: i18n.t("smsConfiguration.create"),
            link: "SmsConfiguration.Create",
          },
          {
            name: i18n.t("smsConfiguration.send_exam"),
            link: "Dashboard",
          },
        ],
      },
    },
    {
      path: "edit/:id",
      name: "SmsConfiguration.Edit",
      component: Edit,
      meta: {
        name: i18n.t("survey.step3.title.create"),
        breadcrumbs: [
          {
            name: i18n.t("dashboard.dashboard"),
            link: "Dashboard",
          },
          {
            name: "Formularz badania",
            link: "SmsExam.SmsEdit",
          },
          {
            name: i18n.t("smsConfiguration.edit"),
            link: "SmsConfiguration.Edit",
          },
          {
            name: i18n.t("smsConfiguration.summary"),
            link: "SmsExam.SmsSummary",
          },
        ],
      },
    },
  ],
};
