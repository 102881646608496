<template>
  <form @submit.prevent="">
    <div class="max-w-4xl mx-auto">
      <div class="md-card">
        <md-card-header class="md-card-header-text md-card-header-blue">
          <div class="card-text">
            <h4 class="title">{{ $t("smsConfiguration.create") }}</h4>
          </div>
        </md-card-header>

        <div class="md-card-content">
          <div class="md-layout">
            <label class="md-layout-item md-size-20 md-form-label">
              {{ $t("smsConfiguration.step_2.exam_start") }}
            </label>
            <div class="md-layout-item">
              <md-radio v-model="smsExamConfiguration.send_type" value="datepicked">{{
                $t("smsConfiguration.step_2.datepicked")
              }}</md-radio>
              <md-radio v-model="smsExamConfiguration.send_type" value="ranges">{{
                $t("smsConfiguration.step_2.ranges")
              }}</md-radio>
            </div>
          </div>
          <HsMultipleDatesDatepicker
            v-if="smsExamConfiguration.send_type === 'datepicked'"
            v-model="smsExamConfiguration.sms_dates"
            :label="$t('smsConfiguration.step_2.space')"
          />
          <HsRangeDatepicker
            v-if="smsExamConfiguration.send_type === 'ranges'"
            v-model="smsExamConfiguration.sms_dates"
            :label="$t('smsConfiguration.step_2.space')"
          />
          <HsTextInput
            v-model="smsExamConfiguration.sms_body"
            :validation="{ required: true }"
            title="sms_body"
            :label="$t('smsConfiguration.step_2.body')"
          ></HsTextInput>
          <HsClockPicker
            v-model="smsExamConfiguration.time"
            :validation="{ required: true }"
            title="time"
            :label="$t('smsConfiguration.step_2.time')"
          ></HsClockPicker>
        </div>
      </div>
      <div class="max-w-4xl mx-auto">
        <md-button class="md-raised md-rose" @click="goBack">
          {{ $t("global.back") }}
        </md-button>
        <md-button @click.prevent="submitForm" class="md-raised md-info float-right"
          >{{ $t("survey.next") }}
        </md-button>
      </div>
    </div>
  </form>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { deleteAlert } from "@/helpers/swal";
import HsTextInput from "@/components/Forms/Inputs/HsTextInput";
import HsMultipleDatesDatepicker from "@/components/Forms/Inputs/HsMultipleDatesDatepicker";
import HsRangeDatepicker from "@/components/Forms/Inputs/HsRangeDatepicker";
import HsClockPicker from "@/components/Forms/Inputs/HsClockPicker.vue";

export default {
  name: "Create",
  props: {
    formTitle: String,
    data: Object,
  },
  components: {
    HsTextInput,
    HsMultipleDatesDatepicker,
    HsRangeDatepicker,
    HsClockPicker,
  },
  data() {
    return {
      smsExamConfiguration: {
        send_type: "datepicked",
        sms_dates: [],
        sms_body: null,
        time: null,
      },
      globalLoading: true,
      currentPage: 1,
      isImporting: false,
      isEditing: false,
      inputValue: "10:10",
    };
  },
  computed: {
    ...mapState("smsExam", ["smsExam"]),
    ...mapState("smsExam", {
      currentSmsExamConfiguration: "smsExamConfiguration",
    }),
    ...mapState("participant", ["participant"]),
  },
  methods: {
    ...mapActions("smsExamConfiguration", [
      "getSmsExamConfiguration",
      "storeSmsExamConfiguration",
      "updateSmsExamConfiguration",
    ]),
    ...mapActions("smsExam", ["getSmsExam"]),
    ...mapActions("participant", ["getParticipant"]),

    async submit() {
      await this.storeSmsExamConfiguration({
        id: this.$route.params.id,
        payload: this.smsExamConfiguration,
      });
      this.$notify({
        text: this.$t("survey.configuration_created"),
        type: "success",
        group: "notifications",
      });
      this.$router.push({
        name: "SmsExam.SmsSummary",
        params: { id: this.$route.params.id },
      });
    },
    async submitForm() {
      const result = await this.$validator.validateAll();
      if (result) {
        this.submit();
      }
    },
    async goBack() {
      const { dismiss } = await deleteAlert({
        title: this.$t("smsConfiguration.back.title"),
        text: this.$t("smsConfiguration.back.text"),
        confirmButtonText: this.$t("smsConfiguration.back.confirmButtonText"),
      });

      if (!dismiss) {
        this.$router.push({
          name: "SmsExam.SmsEdit",
          params: { id: this.$route.params.id },
        });
      }
    },
    async setDefaultGenderBody() {
      if (this.participant.gender == 0) {
        this.smsExamConfiguration.sms_body =
          "Czy zrobiłaś dzisiaj wszystko co w Twojej mocy, aby...";
      } else {
        this.smsExamConfiguration.sms_body =
          "Czy zrobiłeś dzisiaj wszystko co w Twojej mocy, aby...";
      }
    },
  },
  async created() {
    this.$store.commit("layout/startLoading");
    await Promise.all([this.getSmsExam({ id: this.$route.params.id })]);
    await Promise.all([this.getParticipant({ id: this.smsExam.participant_id })]);
    this.$store.commit("layout/finishLoading");
    await this.setDefaultGenderBody();
  },
};
</script>

<style lang="scss" scoped>
.md-table {
  margin-right: 20px;
}

.md-table-head {
  padding: 1rem 1.4rem;
}

@media screen and (max-width: 768px) {
  .md-table {
    border: 0;
    margin-right: 0px;
  }
  .md-table .md-table-head {
    display: none;
  }
}
</style>
