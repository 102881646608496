var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('div',{staticClass:"max-w-4xl mx-auto"},[_c('div',{staticClass:"md-card"},[_c('md-card-header',{staticClass:"md-card-header-text md-card-header-blue"},[_c('div',{staticClass:"card-text"},[_c('h4',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("smsConfiguration.create")))])])]),_c('div',{staticClass:"md-card-content"},[_c('div',{staticClass:"md-layout"},[_c('label',{staticClass:"md-layout-item md-size-20 md-form-label"},[_vm._v(" "+_vm._s(_vm.$t("smsConfiguration.step_2.exam_start"))+" ")]),_c('div',{staticClass:"md-layout-item"},[_c('md-radio',{attrs:{"value":"datepicked"},model:{value:(_vm.smsExamConfiguration.send_type),callback:function ($$v) {_vm.$set(_vm.smsExamConfiguration, "send_type", $$v)},expression:"smsExamConfiguration.send_type"}},[_vm._v(_vm._s(_vm.$t("smsConfiguration.step_2.datepicked")))]),_c('md-radio',{attrs:{"value":"ranges"},model:{value:(_vm.smsExamConfiguration.send_type),callback:function ($$v) {_vm.$set(_vm.smsExamConfiguration, "send_type", $$v)},expression:"smsExamConfiguration.send_type"}},[_vm._v(_vm._s(_vm.$t("smsConfiguration.step_2.ranges")))])],1)]),(_vm.smsExamConfiguration.send_type === 'datepicked' && !_vm.isLoading)?_c('HsMultipleDatesDatepicker',{attrs:{"label":_vm.$t('smsConfiguration.step_2.space')},model:{value:(_vm.smsExamConfiguration.sms_dates),callback:function ($$v) {_vm.$set(_vm.smsExamConfiguration, "sms_dates", $$v)},expression:"smsExamConfiguration.sms_dates"}}):_vm._e(),(_vm.smsExamConfiguration.send_type === 'ranges' && !_vm.isLoading)?_c('HsRangeDatepicker',{attrs:{"label":_vm.$t('smsConfiguration.step_2.space')},model:{value:(_vm.smsExamConfiguration.sms_dates),callback:function ($$v) {_vm.$set(_vm.smsExamConfiguration, "sms_dates", $$v)},expression:"smsExamConfiguration.sms_dates"}}):_vm._e(),_c('HsTextInput',{attrs:{"validation":{
            required: true,
          },"title":"sms_body","label":_vm.$t('smsConfiguration.step_2.body')},model:{value:(_vm.smsExamConfiguration.sms_body),callback:function ($$v) {_vm.$set(_vm.smsExamConfiguration, "sms_body", $$v)},expression:"smsExamConfiguration.sms_body"}}),_c('HsClockPicker',{attrs:{"validation":{ required: true },"title":"time","label":_vm.$t('smsConfiguration.step_2.time')},model:{value:(_vm.smsExamConfiguration.time),callback:function ($$v) {_vm.$set(_vm.smsExamConfiguration, "time", $$v)},expression:"smsExamConfiguration.time"}})],1)],1),_c('div',{staticClass:"max-w-4xl mx-auto"},[_c('md-button',{staticClass:"md-rose",attrs:{"to":{
          name: 'SmsExam.SmsEdit',
          params: {
            id: this.$route.params.id,
            configurationId: this.currentSmsExamConfiguration.id,
          },
        }}},[_vm._v(" "+_vm._s(_vm.$t("global.back"))+" ")]),_c('md-button',{staticClass:"md-raised md-info float-right",on:{"click":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t("survey.next"))+" ")])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }