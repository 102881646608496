export default {
  use_study: "Użyj narzędzia",
  next: "Przejdź dalej",
  declinate: "Wpisz odmiany",
  create_success: "Badanie utworzone pomyślnie.",
  configuration_created: "Potwierdź konfigurację badania.",
  save: "Zapisz",
  send: "Załóż badanie",
  step1: {
    title: {
      create: "Dodaj badanie - Utwórz nowe badanie",
      edit: "Edytuj badanie",
    },
    subtitle: {
      create: "Utwórz nowe badanie",
      edit: "Edytuj badanie",
    },
    name: "Imię uczestnika",
    last_name: "Nazwisko uczestnika",
    email: "Adres e-mail",
    gender: "Płeć",
    phone_number: "Numer telefonu",
    start_date: "Początek badania",
    end_date: "Koniec badania",
    employees: "Wielkość zespołu",
    position: "Stanowisko",
    country_code: "Numer kierunkowy",
    first_declination: {},
    last_declination: {
      title: "",
    },
  },
  step2: {
    title: {
      create: "Personalizuj dane badania",
      edit: "Edytuj personalizowane dane",
    },
    last_name: "Nazwisko",
    company_name: "Firma",
    phone_number: "Numer telefonu",
  },
  step3: {
    title: {
      create: "Dodaj respondentów - Przypisz respondentów do badania",
      additional: "Zarządzanie respondentami",
      edit: "Edytuj respondentów",
    },
    subtitle: "Przypisz respondentów",
    name: "Imię",
    last_name: "Nazwisko",
    email: "Adres e-mail",
    add_respondent: "Dodaj respondenta",
    import: "Importuj",
    delete_title: "Czy napewno chcesz usunąć respondenta?",
    empty: "Brak przypisanych respondentów",
  },
  declination: {
    title: "Wpisz odmiany imienia/nazwiska",
    subtitle:
      "Aby poprawnie generować raporty zaleca się podanie odmian imienia/nazwiska uczestnika.",
    nominative: "Mianownik (kto? co?):",
    genitive: "Dopełniacz (kogo? czego?):<br> Nie ma..",
    dative: "Celownik (komu? czemu?):<br> Przyglądam się..",
    accusative: "Biernik (kogo? co?):<br> Widzę..",
    instrumental: "Narzędnik (z kim? z czym?):<br> Idę z..",
    locative: "Miejscownik (o kim? o czym?):<br> Mówię o..",
    vocative: "Wołacz (hej!):<br> O..",
  },
};
