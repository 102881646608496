<template>
  <ul class="nav nav-mobile-menu">
    <li v-if="!isLogged">
      <router-link :to="{ name: 'Auth.Login' }" class="dropdown-toggle justify-center">
        <p>{{ $t("auth.login") }}</p>
      </router-link>
    </li>
    <li v-if="isLogged">
      <router-link :to="{ name: 'AvailableStudies' }" class="dropdown-toggle justify-center">
        <p>{{ $t("dashboard.available_studies") }}</p>
      </router-link>
    </li>
    <li v-if="isLogged">
      <router-link :to="{ name: 'Dashboard' }" class="dropdown-toggle justify-center">
        <p>{{ $t("dashboard.my_studies") }}</p>
      </router-link>
    </li>
    <li v-if="isLogged" @click="handleLogout">
      <a class="dropdown-toggle justify-center">
        <i class="material-icons">power_settings_new</i>
        <p>{{ $t("auth.logout") }}</p>
      </a>
    </li>
  </ul>
</template>
<script>
import { mapActions, mapState } from "vuex";
export default {
  computed: {
    ...mapState("auth", ["user", "isLogged"]),
  },
  methods: {
    ...mapActions("auth", ["logout"]),

    async handleLogout() {
      await this.logout();
      this.$router.push({ name: "Auth.Login" });
    },
  },
};
</script>

<style lang="scss" scoped>
.sidebar .nav i,
.off-canvas-sidebar .nav i {
  float: 0;
  margin-right: 0 !important;
}

.sidebar .nav li a,
.off-canvas-sidebar .nav li a {
  display: flex;
}
</style>
