export default {
  comment_title: "Tutaj możesz wpisać komentarz do raportu.",
  comment_subtitle: "Część {roundNumber}, sekcja {sectionNumber}",
  no_of_answers: "liczba odpowiedzi",
  next: "Kolejna sekcja",
  prev: "Poprzednia sekcja",
  alert_sections: "Proszę zwróć uwagę, że wykresy w zależności od typu mogą mieć różne skale.",
  select_section: "Wybierz sekcję",
  report: "Zakończ i wygeneruj raport",
  summary: "Przejdź do podsumowania raportu",
  alert: {
    title: "Koniec sekcji",
    text: "Czy chcesz zapisać komentarz i przejść do nastepnej sekcji?",
  },
  save_comment: "Zapisz komentarz",
  comment_saved: "Zapisano komentarz!",
  content_placeholder: "To jest miejsce do wpisania komentarza i pytań do uczestnika",
  other_responses: "Inne odpowiedzi",
  skipped_count: "Pominiętych odpowiedzi",
};
