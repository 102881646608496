import Vue from "vue";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import { env } from "@/helpers/env";

if (env.VUE_APP_ENVIRONMENT !== "local") {
  const ignoreErrors = [/navigation/gm];
  Sentry.init({
    Vue,
    environment: env.VUE_APP_ENVIRONMENT,
    dsn: env.VUE_APP_SENTRY_KEY,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    ignoreErrors,
  });
}

export default Sentry;
