import api from "@/helpers/api";

export default {
  async getBanners({ commit }) {
    const {
      data: { data },
    } = await api.get(`coach/banner`);
    commit("setBanners", data);
  },
};
