export default {
  dashboard: "Panel narzędzi i procesów",
  welcome: "Strona powitalna",
  hello: "Witaj",
  welcome_first_paragraph:
    "To jest Twoje konto na Platformie NTC (Narzędzia Trenera i Coacha). Znajdziesz tutaj narzędzia edukacyjne wspierające procesy rozwoju i doskonalenia kompetencji.",
  welcome_second_paragraph:
    "Dzięki tej platformie będziesz mógł poznawać dostępne narzędzia, zapraszać swoich klientów do udziału w wybranych procesach i zarządzać nimi.",
  welcome_third_paragraph:
    "Mamy nadzieję, że będzie to przyjazne miejsce, dzięki któremu Ty i Twoi klienci będą mogli wzrastać i rozwijać się w wybranych obszarach.",
  welcome_signature: "Serdecznie pozdrawiamy i życzymy dobrych doświadczeń edukacyjnych.",
  welcome_team: "Zespół NTC",
  created_by: "Twórcą i administratorem Platformy NTC jest:",
  contact: "Kontakt",
  contact_tutor: "Kontakt do opiekuna merytorycznego",
  info: "Informacja",
  available_studies: "Dostępne narzędzia",
  my_studies: "Moje narzędzia",
  go_studies: "Przejdź do swoich narzędzi",
  about_studies:
    'Poniżej znajdziesz listę dostępnych do zakupu narzędzi edukacyjnych platformy NTC. Aby przejść do Twoich narzędzi kliknij przycisk "Przejdź do swoich narzędzi" w kafelku obok albo użyj przycisku "Moje narzędzia" umieszczonego na górze strony.',
  referal_alert_title:
    "Poleć innemu trenerowi Narzędzia Trenera i Coacha, a otrzymasz 10% zniżki na kolejny zakup na platformie.",
  referal_alert_description: "Wystarczy, że poda Twoje imię i nazwisko podczas rejestracji",
};
