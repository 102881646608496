<template>
  <div class="uploader" @dragover="dragover" @dragleave="dragleave" @drop="drop">
    <input
      type="file"
      name="file"
      id="fileInput"
      class="hidden-input"
      @change="onChange"
      ref="file"
      :accept="type === 'image' ? '.png' : ''"
    />
    <div class="uploader__wrapper">
      <div class="uploader__content">
        <div class="uploader__logo">
          <div class="uploader__icon">
            <img
              v-if="type === 'image'"
              :src="
                files.length
                  ? generateThumbnail(files[0])
                  : require('@/assets/images/uploader_image.svg')
              "
              alt="file"
            />
            <img
              v-if="type === 'file'"
              :src="require('@/assets/images/uploader_file.svg')"
              alt="file"
            />
          </div>
          <button
            v-if="files.length"
            class="uploader__remove"
            type="button"
            @click="remove(files.indexOf(files[0]))"
            :title="$t('global.remove')"
          >
            {{ $t("global.remove") }}
          </button>
        </div>

        <div v-if="files.length">{{ makeName(files[0].name) }}</div>
        <div v-else>
          {{ type === "image" ? $t("uploader.info_image") : $t("uploader.info_file") }}
        </div>
      </div>
      <label for="fileInput" class="uploader__button">
        <span>{{ $t("uploader.choose") }}..</span>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: "Uploader",
  props: {
    type: {
      type: String,
      default: "image",
    },
  },
  data() {
    return {
      isDragging: false,
      files: [],
    };
  },
  methods: {
    onChange() {
      if (this.$refs.file.files.length > 0 && this.$refs.file.files[0].size > 10485760) {
        this.$notify({
          text: this.$t("uploader.wrong_size"),
          type: "error",
          group: "notifications",
        });
        return;
      }
      this.files = [...this.$refs.file.files];
      this.$emit("input", this.files[0]);
    },
    generateThumbnail(file) {
      let fileSrc = URL.createObjectURL(file);
      setTimeout(() => {
        URL.revokeObjectURL(fileSrc);
      }, 1000);
      return fileSrc;
    },
    makeName(name) {
      return (
        name.split(".")[0].substring(0, 30) + "..." + name.split(".")[name.split(".").length - 1]
      );
    },
    remove(i) {
      this.files.splice(i, 1);
      this.$emit("input", null);
    },
    dragover(e) {
      e.preventDefault();
      this.isDragging = true;
    },
    dragleave() {
      this.isDragging = false;
    },
    drop(e) {
      e.preventDefault();
      const acceptableFormats = ["image/png"];
      if (
        this.type === "image" &&
        (e.dataTransfer.files.length > 1 ||
          !acceptableFormats.includes(e.dataTransfer.files[0].type))
      ) {
        this.isDragging = false;
        return;
      }
      if (e.dataTransfer.files[0].size > 10485760) {
        this.$notify({
          text: this.$t("uploader.wrong_size"),
          type: "error",
          group: "notifications",
        });
        return;
      }
      this.$refs.file.files = e.dataTransfer.files;
      this.onChange();
      this.isDragging = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.uploader {
  padding: 14px 18px;
  margin: 25px 0;
  border: 1px dashed #27569a;
  border-radius: 4px;
  &__wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__content {
    display: flex;
    align-items: center;
  }
  &__button {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    background: #1d2242;
    color: #fff;
    border-radius: 6px;
    padding: 4px 12px;
    cursor: pointer;
  }
  &__logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 8px;
  }
  &__icon {
    width: 42px;
    height: 42px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      pointer-events: none;
    }
  }
  &__remove {
    margin-top: 6px;
    font-size: 12px;
    font-weight: 600;
  }
}
.hidden-input {
  opacity: 0;
  overflow: hidden;
  position: absolute;
  width: 1px;
  height: 1px;
}
</style>
