import api from "@/helpers/api";

export default {
  async getRespondents({ commit }, exam) {
    const result = await api.get(`coach/respondents/${exam.id}`);
    const data = result.data.data;

    commit("setRespondents", data);
  },

  async getRoundRespondents({ commit }, id) {
    const result = await api.get(`coach/respondents/round/${id.id}`);
    const data = result.data.data;

    commit("setRespondents", data);
  },

  async storeRespondents({ commit }, { id, payload }) {
    const result = await api.post(`coach/respondents/${id}`, payload);
    const data = result.data.data;
    commit("setRespondents", data);
  },

  async updateRespondents(store, { id, payload }) {
    await api.put(`coach/respondents/${id}`, payload);
  },

  async updateRoundRespondents(store, { id, payload }) {
    await api.put(`coach/respondents/round/${id}`, payload);
  },

  async importRespondents(store, payload) {
    const result = await api.post(`coach/respondents/import`, payload);

    return result.data.data;
  },
};
