<template>
  <div class="md-layout">
    <label class="md-layout-item md-size-20 md-form-label">
      {{ label }}
    </label>
    <div class="md-layout-item">
      <date-picker v-model="range" :min-date="tomorrowDate" is-range />
    </div>
  </div>
</template>
<script>
import moment from "moment";

export default {
  name: "HsRangeDatepicker",
  props: {
    hasTime: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
    value: {
      type: [Array, String],
      default: null,
    },
  },
  data() {
    return {
      range: {},
    };
  },
  computed: {
    tomorrowDate() {
      const today = new Date();

      return new Date().setDate(today.getDate() + 1);
    },
  },
  created() {
    if (this.value.length) {
      this.setDates();
    }
  },
  methods: {
    setDates() {
      const values = JSON.parse(this.value);
      this.range = {
        start: moment(values.start, "DD-MM-YYYY").toISOString(),
        end: moment(values.end, "DD-MM-YYYY").toISOString(),
      };
    },
    emitChange() {
      this.range["start"] = moment(this.range.start).format("DD-MM-YYYY");
      this.range["end"] = moment(this.range.end).format("DD-MM-YYYY");
      this.$emit("input", JSON.stringify(this.range));
    },
  },
  watch: {
    range() {
      this.emitChange();
    },
  },
};
</script>
