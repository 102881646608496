import api from "@/helpers/api";

export default {
  async getStudy({ commit }, studyId) {
    const response = await api.get(`coach/studies/${studyId}`);
    commit("setStudy", response.data.data);
  },
  async getStudies({ commit }) {
    const response = await api.get("coach/studies");
    commit("setStudies", response.data.data);
  },
};
