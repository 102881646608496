export default {
  setPolicy(state, data) {
    state.policy = data;
  },
  setRules(state, data) {
    state.rules = data;
  },
  setCookies(state, data) {
    state.cookies = data;
  },
};
