export default {
  faq: "FAQ",
  welcome: "Witaj",
  title: "Jeżeli masz problemy techniczne skontaktuj się z nami.",
  send_success: "Wiadomość wysłana. Wkrótce odezwiemy się do Ciebie w tej sprawie.",
  technical_support: "Wsparcie merytoryczne",
  enter_message: "Wprowadź treść...",
  coach: {
    id: "ID",
    first_name: "Imię",
    last_name: "Nazwisko",
    email: "E-mail",
    phone: "Telefon",
  },
  cancel: "Zrezygnuj",
  send_ticket: "Wyślij wiadomość",
  questions_and_answers: "Pytania i odpowiedzi",
};
