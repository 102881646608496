import api from "@/helpers/api";

export default {
  async getSmsExamConfiguration({ commit }, { smsExamId, roundNumber }) {
    const {
      data: { data },
    } = await api.get(`coach/sms-exams/${smsExamId}/round/${roundNumber}`);

    commit("setSmsExamConfiguration", data);
  },

  async storeSmsExamConfiguration({ commit }, { id, payload }) {
    const {
      data: { data },
    } = await api.post(`coach/sms-exams/${id}/round`, payload);
    commit("setSmsExamConfiguration", data);
  },

  async updateSmsExamConfiguration(store, { smsExamId, roundNumber, payload }) {
    await api.put(`coach/sms-exams/${smsExamId}/round/${roundNumber}`, payload);
  },

  async addSmsExamRoundComment(store, { roundId, payload }) {
    await api.post(`/coach/sms-comments/${roundId}`, payload);
  },

  async updateSmsExamRoundComment(store, { roundId, commentId, payload }) {
    await api.put(`/coach/sms-comments/${roundId}/${commentId}`, payload);
  },
};
