export default {
  registration: "Rejestracja trenera",
  complete_registration: "Finalizacja rejestracji trenera",
  register_new: "Zarejestruj nowego trenera",
  first_name: "Imię",
  last_name: "Nazwisko",
  email: "E-mail",
  company_name: "Firma",
  phone_number: "Numer Telefonu",
  nip_number: "Numer NIP",
  address: "Adres (ulica, numer)",
  postal_code: "Kod pocztowy",
  city: "Miasto",
  agreement_1: "Zgoda na regulamin i politykę prywatności",
  agreement_2: "Zgoda na przetwarzanie danych osobowych",
  referal_data: "Coach polecający",
  confirm_email:
    "Prosimy zweryfikuj swoje konto poprzez kliknięcie w przycisk w wiadomości e-mail, którą Tobie wysłaliśmy.",
  register: "Zarejestruj się",
};
