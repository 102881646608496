export default {
  first_name: "imię",
  last_name: "nazwisko",
  password: "hasło",
  email: "email",
  phone_number: "numer telefonu",
  nip_number: "numer NIP",
  company_name: "firma",
  address: "adres",
  postal_code: "kod pocztowy",
  city: "miasto",
  old_password: "obecne hasło",
  new_password: "nowe hasło",
  password_confirmation: "powtórz nowe hasło",
};
