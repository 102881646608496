import DashboardLayout from "@/pages/Dashboard/Layout/DashboardLayout";

import EditProfile from "@/pages/Coach/EditProfile";
import ChangePassword from "@/pages/Coach/ChangePassword";

import i18n from "../i18n";

export default {
  path: "/coach",
  component: DashboardLayout,
  name: "Coach",
  children: [
    {
      path: "profile/edit",
      name: "Coach.EditProfile",
      component: EditProfile,
      meta: {
        name: i18n.t("profile.edit_profile"),
        breadcrumbs: [
          {
            name: i18n.t("dashboard.dashboard"),
            link: "Dashboard",
          },
          {
            name: i18n.t("profile.edit_profile"),
            link: "Coach.EditProfile",
          },
        ],
      },
    },
    {
      path: "password",
      name: "Coach.ChangePassword",
      component: ChangePassword,
      meta: {
        name: i18n.t("profile.change_password"),
        breadcrumbs: [
          {
            name: i18n.t("dashboard.dashboard"),
            link: "Dashboard",
          },
          {
            name: i18n.t("profile.change_password"),
            link: "Coach.ChangePassword",
          },
        ],
      },
    },
  ],
};
