import api from "@/helpers/api";

export default {
  async getCoachProfile({ commit }, { coachId }) {
    const {
      data: { data },
    } = await api.get(`coach/coach-profiles/${coachId}`);

    commit("setCoachProfile", data);
  },

  async updateProfile(
    store,
    {
      id,
      firstName,
      lastName,
      email,
      phoneNumber,
      nipNumber,
      companyName,
      address,
      postalCode,
      city,
      referal_data,
    }
  ) {
    await api.put(`coach/coach-profiles/${id}`, {
      first_name: firstName,
      last_name: lastName,
      email,
      referal_data,
      coach_profile: {
        phone_number: phoneNumber,
        nip_number: nipNumber,
        company_name: companyName,
        address: address,
        postal_code: postalCode,
        city: city,
      },
    });
  },

  async changeUserPassword(store, { oldPassword, newPassword, passwordConfirmation }) {
    await api.post("coach/coach-profiles/change-password", {
      old_password: oldPassword,
      new_password: newPassword,
      password_confirmation: passwordConfirmation,
    });
  },

  async getUncompletedCoachProfile({ commit }, { token }) {
    const {
      data: { data },
    } = await api.get(`coach/coach-profiles/token/${token}`);

    commit("setCoachProfile", data);
  },
};
