import api from "@/helpers/api";

export default {
  async getCoachStudies({ commit }) {
    const response = await api.get("coach/coach-studies");
    commit("setCoachStudies", response.data.data);
  },

  async getCoachMissingStudies({ commit }) {
    const response = await api.get("coach/coach-studies/missing");
    commit("setCoachMissingStudies", response.data.data);
  },
};
