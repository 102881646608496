import api from "@/helpers/api";

export default {
  async getStudyRespondGroups({ commit }, { id }) {
    const {
      data: { data },
    } = await api.get(`coach/study-respond-groups/${id}`);
    commit("setStudyRespondGroups", data);
  },
};
