import api from "@/helpers/api";

export default {
  async getSections({ commit }, payload) {
    const result = await api.get(`coach/study-sections`, { params: payload });
    const data = result.data.data;

    commit("setSections", data.data);
  },

  async attachSections(store, { examId, payload }) {
    await api.post(`coach/exams/${examId}/attach-study-sections`, payload);
  },

  async getExamCurrentSections({ commit }, { id }) {
    const response = await api.get(`coach/exams/${id}/study-sections`);
    await commit("setExamCurrentSections", response.data.data);
  },
};
