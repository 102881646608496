import Vue from "vue";
import VueRouter from "vue-router";
import Vuex from "vuex";
import VueScrollTo from "vue-scrollto";
import createPersistedState from "vuex-persistedstate";

import DashboardPlugin from "./material-dashboard";
// Plugins
import App from "./App.vue";
import i18n from "./i18n";
import { Validator } from "vee-validate";
import * as pl from "vee-validate/dist/locale/pl";
import attributes from "@/trans/pl/attributes";

// router setup
import routes from "./routes/routes";

// vuex modules
import modules from "./store";

// api
import api from "./helpers/api";
import { errorAlert, infoAlert } from "./helpers/swal";

// sentry
import "@/utils/sentry";

// plugin setup
Vue.use(VueRouter);
Vue.use(DashboardPlugin);
Vue.use(Vuex);
Vue.use(VueScrollTo);

pl.attributes = attributes;
Validator.localize("pl", pl);

// configure router
const router = new VueRouter({
  mode: "history",
  base: "/",
  routes,
  linkExactActiveClass: "nav-item active",
  scrollBehavior: () => {
    return { x: 0, y: 0 };
  },
});

const store = new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules,
  plugins: [
    createPersistedState({
      key: "HalibutAnkietyCMS",
      paths: ["auth"],
    }),
  ],
});

router.beforeEach((to, from, next) => {
  if (to.name.indexOf("Auth.") > -1 || store.state.auth.isLogged) {
    next();
  } else if (to.name.indexOf("Unauth.") > -1) {
    next();
  } else
    next({
      name: "Auth.Login",
    });
});

api.interceptors.request.use((config) => {
  if (store.state.auth.isLogged) {
    config.headers.common["Authorization"] = `Bearer ${store.state.auth.tokens.access_token}`;
    return config;
  }
  return config;
});

api.interceptors.response.use(null, function (error) {
  if (error.response) {
    if (error.response?.status === 401 && store.state.auth.isLogged) {
      router.push({ name: "Auth.Login" });
      infoAlert({
        title: i18n.t("error.unauthorized_error"),
      });

      return error;
    }
    if (error.response.status === 422) {
      const errors = error.response.data.errors;
      Object.keys(errors).forEach((key) => {
        Vue.notify({
          text: errors[key][0],
          type: "error",
          group: "notifications",
        });
      });
    } else if (error.response.status === 500) {
      errorAlert({
        title: i18n.t("error.server_error"),
      });
    } else if (error.response.status !== 401) {
      console.error(error);
    }
  }
  return Promise.reject(error);
});

/* eslint-disable no-new */
new Vue({
  el: "#app",
  render: (h) => h(App),
  router,
  store,
  i18n,
  mounted: function () {
    document.title = "Narzędzia Trenera i Coacha";
  },
});
