export default {
  setSections(state, data) {
    state.sections = data;
  },
  clearSections(state) {
    state.sections = {};
  },
  setExamCurrentSections(state, data) {
    state.examCurrentSections = Object.values(data);
  },
};
