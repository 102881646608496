var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{staticClass:"max-w-4xl mx-auto",on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('div',{staticClass:"md-card"},[_c('md-card-header',{staticClass:"md-card-header-text md-card-header-blue"},[_c('div',{staticClass:"card-text"},[_c('h4',{staticClass:"title"},[_vm._v(_vm._s(_vm.formTitle))])])]),_c('div',{staticClass:"md-card-content"},[_c('HsTextInput',{attrs:{"label":_vm.$t('profile.old_password'),"title":"old_password","validation":{
          required: true,
        }},model:{value:(_vm.formData.oldPassword),callback:function ($$v) {_vm.$set(_vm.formData, "oldPassword", $$v)},expression:"formData.oldPassword"}},[(_vm.errors.has('old_password'))?_c('span',{staticClass:"form-validate"},[_vm._v(_vm._s(_vm.errors.first("old_password"))+" ")]):_vm._e()]),_c('HsTextInput',{attrs:{"label":_vm.$t('profile.new_password'),"title":"new_password","validation":{
          required: true,
        }},model:{value:(_vm.formData.newPassword),callback:function ($$v) {_vm.$set(_vm.formData, "newPassword", $$v)},expression:"formData.newPassword"}},[(_vm.errors.has('new_password'))?_c('span',{staticClass:"form-validate"},[_vm._v(_vm._s(_vm.errors.first("new_password"))+" ")]):_vm._e()]),_c('HsTextInput',{attrs:{"label":_vm.$t('profile.password_confirmation'),"title":"password_confirmation","validation":{
          required: true,
        }},model:{value:(_vm.formData.passwordConfirmation),callback:function ($$v) {_vm.$set(_vm.formData, "passwordConfirmation", $$v)},expression:"formData.passwordConfirmation"}},[(_vm.errors.has('password_confirmation'))?_c('span',{staticClass:"form-validate"},[_vm._v(_vm._s(_vm.errors.first("password_confirmation"))+" ")]):_vm._e()])],1)],1),_c('md-button',{staticClass:"md-rose",on:{"click":function($event){return _vm.$emit('cancel')}}},[_vm._v(" "+_vm._s(_vm.$t("global.back"))+" ")]),_c('md-button',{staticClass:"md-info float-right",attrs:{"type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t("global.save_changes"))+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }