export default {
  exam_for: "Badanie dla",
  evaluation_for: "Ewaluacja",
  dates: "Data badania",
  type: "Typ badania",
  status: "Status",
  exam_progress: "Postęp",
  progress: "Odpowiedzi respondentów",
  manage_respondents: "Zarządzaj respondentami",
  edit_end_date: "Edytuj datę zakończenia",
  see_results: "Podgląd wyników",
  cancel: "Anuluj badanie",
  see_report: "Podgląd raportu",
  generate_report: "Wygeneruj raport",
  generating_in_progress: "Trwa generowanie",
  send_report: "Wyślij raport",
  statuses: {
    draft: "Szkic",
    in_progress: "W trakcie",
    waiting: "Oczekuje",
    done: "Zakończone",
    report_sent: "Wysłano raport",
    report_ready: "Raport gotowy",
    not_started: "Nierozpoczęte",
    first_stage_done: "Zakończone po I etapie",
    second_stage_done: "Zakończone po II etapie",
    cancelled: "Anulowano",
    archived: "Badanie zarchiwizowane",
  },
  types: {
    first: "Badanie właściwe",
    second: "Ewaluacja",
  },
  select_extension_date: "Wybierz datę",
  extend: "Zmień datę",
  select_start_date: "Wybierz datę początkową",
  select_end_date: "Wybierz datę końcową",
  create: "Utwórz",
  create_second_round: "Rozpocznij ewaluację",
  start_exam_title: "Czy na pewno chcesz utworzyć badanie?",
  start_exam_text:
    "Imię i nazwisko: <strong>{name}</strong> <br />" +
    "Płeć: <strong>{gender}</strong><br />" +
    "Firma: <strong>{company}</strong> <br />" +
    "Trwanie badania: <strong>{startDate} - {endDate}</strong><br />",
  start_exam_participant:
    'Liczba respondentów w grupie "{groupName}": <strong>{number}</strong><br />',
  exam_start: "Rozpocznij badanie",
  not_enough_sections_title: "Nie wybrano żadnych sekcji!",
  not_enough_sections_text: "Aby kontynuować musisz wybrać co najmniej jedną sekcję.",
  explain_dates:
    "Ustaw datę rozpoczęcia i zakończenia badania. Rekomendujemy, aby okres badania obejmował minimum 10 dni.",
  explain_exam_end:
    "* Jeśli wszyscy respondenci zakończą badanie przed datą zakończenia, badanie zostanie automatycznie zakończone.",
  generate_report_first: "Wygeneruj",
  generate_report_second: "i otwórz raport",
  refresh_first: "Raport jest",
  refresh_second: "generowany,",
  refresh_third: "Odśwież stronę",
  enter_comments: "Wpisz komentarze",
  cancelOpts: {
    title: "Czy na pewno chcesz anulować badanie?",
    text: "Upewnij się czy chcesz anulować ankietę.",
    success: "Pomyślnie anulowano ankietę",
  },
  end_date_is_required: "Nie wpisano daty zakończenia",
  study_archived: "Badanie zarchiwizowane",
};
