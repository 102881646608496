export default {
  title: "Powiadomienia uczestnika",
  notifications_type: {
    study: "Badanie",
    system: "Systemowe",
  },
  show_report: "Zobacz raport",
  empty: "Brak powiadomień",
  marked_as_read: "Powiadomienie oznaczono jako przeczytane",
};
