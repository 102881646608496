<template>
  <span>
    <slide-y-down-transition>
      <md-icon class="error" v-show="field && field.validated && !field.valid">close</md-icon>
    </slide-y-down-transition>
    <slide-y-down-transition>
      <md-icon class="success" v-show="field && field.dirty && field.valid">done</md-icon>
    </slide-y-down-transition>
  </span>
</template>

<script>
import { SlideYDownTransition } from "vue2-transitions";

export default {
  name: "HsValidatorStatus",

  components: {
    SlideYDownTransition,
  },
  props: {
    field: Object,
  },
};
</script>
