<template>
  <div
    id="page-container"
    class="auth-layout"
    :style="{
      backgroundImage: `url(${require('@/assets/images/background.jpg')})`,
    }"
  >
    <a :href="ueDocLink" target="_blank">
      <div class="auth-layout__european-union">
        <img :src="require('@/assets/images/UE_EFRR_rgb-1.jpg')" alt="" />
      </div>
    </a>
    <div id="content-wrap" class="pt-16">
      <router-view :key="$route.path" />
      <notifications position="bottom right" group="notifications" />
    </div>
    <FooterItem />
  </div>
</template>
<script>
import FooterItem from "@/pages/Dashboard/Partials/FooterItem";

export default {
  name: "AuthLayout",
  components: {
    FooterItem,
  },
  computed: {
    ueDocLink() {
      return process.env.VUE_APP_API_URL + "/UE-doc/";
    },
  },
};
</script>
<style lang="scss" scoped>
.auth-layout {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  background-size: cover;
  padding: 100px 0;
  &__european-union {
    position: absolute;
    top: 0;
    right: 0;
    width: 200px;
    height: 65px;
  }
}
</style>
