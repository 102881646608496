import api from "@/helpers/api";

export default {
  async getFaq({ commit }, payload) {
    const {
      data: {
        data: { data },
      },
    } = await api.get(`faq`, { params: payload });

    commit("setFaq", data);
  },
};
