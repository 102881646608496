<template>
  <div class="order-show">
    <!-- Order Completed -->
    <div
      class="order-show__wrapper order-show--completed md:w-1/3"
      v-show="status === acceptedQueries.SUCCESS"
    >
      <div class="order-show__content">
        <md-icon class="md-size-2x">local_mall</md-icon>
        <h3>
          {{ $t("studies.order.thank_you") }}
        </h3>
        <p>
          {{ $t("studies.order.payment_success_text") }}
        </p>
        <div class="order-show__actions" v-if="hasPaymentConfirmation()">
          <md-button class="md-primary outlined text-uppercase">
            {{ $t("studies.order.download_payment_confirmation") }}
            <svg
              width="19"
              height="22"
              viewBox="0 0 19 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.65203 1.70862e-06C1.70615 1.70862e-06 0.940033 0.766118 0.940033 1.71199V19.6879C0.940033 20.6338 1.70615 21.3999 2.65203 21.3999H16.348C17.2938 21.3999 18.06 20.6338 18.06 19.6879V6.41997C18.06 6.19313 17.9701 5.97568 17.8092 5.81476L12.2452 0.250781C12.0843 0.0898529 11.8668 0 11.64 0L2.65203 1.70862e-06ZM11.212 1.63007L16.4299 6.84797H12.068C11.5955 6.84797 11.212 6.46449 11.212 5.99198L11.212 1.63007ZM8.71087 7.96143C9.17568 7.96143 9.56537 8.15787 9.80762 8.51482C10.0028 8.80072 10.0968 9.16833 10.0968 9.63999C10.0968 10.4489 9.73474 11.7188 9.17063 13.079C9.44284 13.6962 9.7604 14.3074 10.0985 14.8629C10.8843 14.868 11.9646 14.9396 12.8454 15.2357C14.1131 15.6603 14.3802 16.3589 14.3802 16.8708C14.3793 17.6078 13.7815 18.4039 12.4676 18.4039C11.496 18.4039 10.413 17.4182 9.47826 16.0683C8.79689 16.0906 8.14967 16.1571 7.55561 16.2572C6.46678 18.0386 5.23287 19.4321 4.29212 19.4321C3.25808 19.4321 2.90949 18.4107 2.90949 17.9442C2.90949 16.4778 4.76405 15.611 6.8133 15.1872C7.20021 14.5273 7.57477 13.8137 7.89834 13.1058C7.42155 11.9236 7.12761 10.7677 7.12761 9.90916C7.12761 9.36475 7.23947 8.92377 7.46031 8.59507C7.73594 8.18591 8.18101 7.96143 8.71087 7.96143H8.71087ZM8.7092 9.15849C8.52345 9.15849 8.48165 9.22188 8.45341 9.26382C8.40547 9.33487 8.32467 9.51369 8.32467 9.90916C8.32467 10.3474 8.41224 10.8601 8.56375 11.4088C8.78802 10.7086 8.9161 10.0715 8.89812 9.56643C8.88443 9.15897 8.78624 9.15849 8.7092 9.15849V9.15849ZM10.9595 16.0967C11.5099 16.7713 12.0482 17.2052 12.4659 17.2052C12.9589 17.2052 13.1798 17.0369 13.1798 16.8691C13.1806 16.7116 12.8999 16.5156 12.4642 16.3693C12.0687 16.2366 11.5519 16.1447 10.9595 16.0967ZM5.86034 16.6702C4.7775 17.0357 4.10655 17.5111 4.10655 17.9425C4.10741 17.9425 4.15521 18.2334 4.29046 18.2334C4.61573 18.2103 5.20807 17.5895 5.86034 16.6702H5.86034Z"
                fill="#27569a"
              />
            </svg>
          </md-button>
        </div>
      </div>
      <span @click="goToDashboard">
        {{ $t("studies.order.back_to_home") }}
      </span>
    </div>
    <!-- End of Order Completed -->

    <!-- Order Failed -->
    <div
      class="order-show__wrapper order-show--failed md:w-1/3"
      v-show="status === acceptedQueries.FAILED && order.status === orderStatuses.PENDING"
    >
      <div class="order-show__content">
        <md-icon class="md-size-2x">warning</md-icon>
        <h3>
          {{ $t("studies.order.processing_error") }}
        </h3>
        <p>
          {{ $t("studies.order.payment_failed_text") }}
        </p>
        <div class="order-show__actions">
          <md-button class="md-primary outlined text-uppercase" @click="goToDashboard">
            {{ $t("studies.order.back_to_home") }}
          </md-button>
          <md-button class="md-primary text-uppercase" @click="repay">
            {{ $t("studies.order.pay_again") }}
          </md-button>
        </div>
      </div>
    </div>
    <!-- End of Order Failed -->
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { confirmAlert } from "@/helpers/swal";

export default {
  name: "Show",
  components: {},
  data() {
    return {
      acceptedQueries: {
        SUCCESS: "success",
        FAILED: "failed",
      },
      orderStatuses: {
        STARTED: "STARTED",
        PENDING: "PENDING",
      },
      status: null,
      order: {},
    };
  },
  computed: {},
  methods: {
    ...mapActions("order", ["getOrder"]),
    ...mapActions("order", ["pay"]),
    goToDashboard() {
      this.$router.push({ name: "Start" });
    },
    hasPaymentConfirmation() {
      //TODO in the final version
      return false;
    },
    async repay() {
      this.$store.commit("layout/startLoading");
      try {
        const paymentUrl = await this.pay({
          orderId: this.order.id,
          payload: {
            coachProfile: this.order.data,
          },
        });

        window.location.href = paymentUrl;
      } catch (e) {
        confirmAlert({
          title: this.$t("studies.order.payment_url_generation_alert"),
        });
      } finally {
        this.$store.commit("layout/finishLoading");
      }
    },
  },
  async created() {
    this.$store.commit("layout/startLoading");
    this.status = this.$route.query.status;
    if (!Object.values(this.acceptedQueries).includes(this.status)) {
      this.goToDashboard();
    }
    this.order = await this.getOrder(this.$route.params.id);
    this.$store.commit("layout/finishLoading");
  },
};
</script>

<style lang="scss" scoped>
$black: #1d2242;
$blue: #27569a;
$blue-darker: #1b3a68;
$green: #62b866;
$red: #ff6c6c;

.order-show {
  display: flex;
  justify-content: center;
  color: $black;
  margin-top: 40px;

  &__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  &__content {
    background: white;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.14);
    border-radius: 6px;
    padding: 24px 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .md-icon {
      color: $green;
    }

    h3 {
      font-weight: 700;
      font-size: 24px;
      margin-top: 24px;
      margin-bottom: 18px;
      color: $green;
      text-align: center;
    }

    p {
      font-weight: 400;
      font-size: 14px;
      margin-bottom: 24px;
      text-align: center;
    }
  }

  .order-show__actions {
    .md-button {
      border-radius: 6px;
      font-size: 14px;
      background-color: $blue !important;

      &.outlined {
        background-color: transparent !important;
        color: $blue !important;
        border: 1px solid $blue;
      }

      svg {
        margin-left: 10px;
      }
    }
  }

  &--completed {
    span {
      cursor: pointer;
      margin-top: 25px;
      color: $blue-darker;
    }
  }

  &--failed {
    .md-icon {
      color: $red;
    }

    h3 {
      color: $red;
    }

    .order-show__actions {
      display: flex;
      flex-direction: column;

      @media only screen and (min-width: 768px) {
        flex-direction: row;

        button:first-of-type {
          margin-right: 16px;
        }
      }
    }
  }
}
</style>
