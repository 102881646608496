import api from "@/helpers/api";

export default {
  async getGoalQuestions({ commit }, { examRoundId }) {
    const {
      data: { data },
    } = await api.get(`coach/exam-rounds/${examRoundId}/goal-questions`);
    commit("setGoalQuestions", data);
  },
  async createGoalQuestion(store, { examRoundId, payload }) {
    await api.post(`coach/exam-rounds/${examRoundId}/goal-questions`, payload);
  },
};
