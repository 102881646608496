export default {
  goals: "Cele",
  title: "Cele uczestnika",
  delete: "Czy na pewno chcesz usunąć ten cel?",
  delete_parent: "Czy na pewno chcesz usunąć ten cel i jego podcele?",
  complete: "Oznacz jako wykonane",
  incomplete: "Oznacz jako niewykonane",
  add_subgoal: "Dodaj podcel",
  max_3: "(maksymalnie 3)",
  description_male:
    "Wpisz cele, nad którymi pracował {participant} w trakcie badania `{examName}`, aby zebrać opinie  respondentów na temat zaobserwowanej zmiany. <br />" +
    "<br />" +
    '<div class="ml-5">W kwestionariuszu pytanie będzie brzmiało: <br />' +
    '<div class="p-3 bg-grey-lighter">W trakcie badania `{examName}` {participant} pracował nad: <br />' +
    '<span class="italic">… tutaj jest miejsce na wpisanie przez Ciebie celu….</span><br />' +
    "Jaką zmianę zaobserwowałeś/aś u niego w tym obszarze? </div></div>",
  description_female:
    "Wpisz cele, nad którymi pracowała {participant} w trakcie badania `{examName}`, aby zebrać opinie  respondentów na temat zaobserwowanej zmiany. <br />" +
    "<br />" +
    '<div class="ml-5">W kwestionariuszu pytanie będzie brzmiało: <br />' +
    '<div class="p-3 bg-grey-lighter">W trakcie badania `{examName}` {participant} pracowała nad: <br />' +
    '<span class="italic">… tutaj jest miejsce na wpisanie przez Ciebie celu….</span><br />' +
    "Jaką zmianę zaobserwowałeś/aś u niej w tym obszarze? </div></div>",
  add_new: "Wpisz nowy cel",
};
