<template>
  <form @submit.prevent="submitForm" class="max-w-4xl mx-auto">
    <div class="md-card">
      <md-card-header class="md-card-header-text md-card-header-blue">
        <div class="card-text">
          <h4 class="title">{{ formTitle }}</h4>
        </div>
      </md-card-header>
      <div class="md-card-content">
        <HsTextInput
          v-model="formData.oldPassword"
          :label="$t('profile.old_password')"
          title="old_password"
          :validation="{
            required: true,
          }"
        >
          <span v-if="errors.has('old_password')" class="form-validate"
            >{{ errors.first("old_password") }}
          </span>
        </HsTextInput>
        <HsTextInput
          v-model="formData.newPassword"
          :label="$t('profile.new_password')"
          title="new_password"
          :validation="{
            required: true,
          }"
        >
          <span v-if="errors.has('new_password')" class="form-validate"
            >{{ errors.first("new_password") }}
          </span>
        </HsTextInput>
        <HsTextInput
          v-model="formData.passwordConfirmation"
          :label="$t('profile.password_confirmation')"
          title="password_confirmation"
          :validation="{
            required: true,
          }"
        >
          <span v-if="errors.has('password_confirmation')" class="form-validate"
            >{{ errors.first("password_confirmation") }}
          </span>
        </HsTextInput>
      </div>
    </div>
    <md-button class="md-rose" @click="$emit('cancel')">
      {{ $t("global.back") }}
    </md-button>
    <md-button class="md-info float-right" type="submit">
      {{ $t("global.save_changes") }}
    </md-button>
  </form>
</template>
<script>
import HsTextInput from "@/components/Forms/Inputs/HsTextInput";

export default {
  name: "ChangePasswordForm",
  props: {
    formTitle: {
      type: String,
      required: true,
    },
  },
  components: {
    HsTextInput,
  },
  data() {
    return {
      formData: {
        oldPassword: "",
        newPassword: "",
        passwordConfirmation: "",
      },
    };
  },
  methods: {
    async submit() {
      this.$emit("form-submit", this.formData);
    },
    async submitForm() {
      const result = await this.$validator.validateAll();

      if (result) {
        this.submit();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.form-validate {
  position: absolute;
  font-size: 12px;
  color: #d9534f;
}
</style>
