import OrderLayout from "@/pages/Order/Layout/OrderLayout";
import Show from "@/pages/Order/Show";
import i18n from "../i18n";

export default {
  path: "/order",
  component: OrderLayout,
  redirect: "/order/show",
  name: "Order",
  children: [
    {
      path: "show/:id",
      name: "Order.Show",
      component: Show,
      meta: {
        name: i18n.t("studies.order.title.show"),
      },
    },
  ],
};
