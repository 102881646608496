export default {
  setParticipantNotifications(state, data) {
    state.participantNotifications = data;
  },
  setPagination(state, { from, to, total, per_page, current_page }) {
    state.notificationsPagination = {
      from,
      to,
      total,
      per_page,
      current_page,
    };
  },
};
