export default {
  setExam(state, data) {
    state.exam = data;
  },
  clearExam(state) {
    state.exam = {};
  },
  setExams(state, data) {
    state.exams = data;
  },
  setPagination(state, { from, to, total, per_page, current_page }) {
    state.pagination = {
      from: from,
      to: to,
      total: total,
      per_page: per_page,
      current_page: current_page,
    };
  },
  setResult(state, data) {
    state.result = data;
  },
};
