<template>
  <div class="px-12 pt-6 m-auto">
    <div class="w-full">
      <h3 class="font-bold" style="text-align: center">{{ this.policy.page_title }}</h3>
      <md-card class="w-full lg:w-2/3 xl:w-2/3 mx-auto">
        <md-card-content v-html="this.policy.page_content" />
      </md-card>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "PolicyPage",
  components: {},
  data() {
    return {
      globalLoading: true,
    };
  },
  computed: {
    ...mapState("textPages", ["policy"]),
  },
  methods: {
    ...mapActions("textPages", ["getPolicy"]),
  },
  async created() {
    try {
      await this.getPolicy();
    } catch (e) {
      console.error(e);
    }
  },
};
</script>
