import api from "../../helpers/api";

export default {
  async storeSmsExam({ commit }, { studyId, payload }) {
    const response = await api.post(`coach/sms-exams/${studyId}`, payload);
    commit("setSmsExam", response.data.data);
  },
  async getSmsExam({ commit }, { id, payload }) {
    const response = await api.get(`coach/sms-exams/${id}`, payload);
    commit("setSmsExam", response.data.data);
  },
  async getSmsExams({ commit }, payload) {
    const response = await api.get("/coach/sms-exams", { params: payload });
    const data = response.data.data;

    commit("setSmsExams", data.data);
    commit("setPagination", data);
  },
  async getSmsResult({ commit }, smsExamId) {
    const response = await api.get(`coach/sms-exams/${smsExamId}/result`);

    commit("setResult", response.data);
  },
  async deleteSmsExam(store, smsExamId) {
    await api.delete(`coach/sms-exams/${smsExamId}`);
  },
  async startSmsExam(store, smsExamId) {
    await api.post(`coach/sms-exams/${smsExamId}/start`);
  },
  async startGeneratingReport(store, { smsExamId, roundId }) {
    await api.post(`coach/sms-exams/${smsExamId}/round/${roundId}/report`);
  },
  async checkReportStatus(store, { roundId }) {
    const {
      data: { data },
    } = await api.get(`coach/sms-exams/round/${roundId}/report-url`);

    return data;
  },
};
