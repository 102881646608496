import DashboardLayout from "@/pages/Dashboard/Layout/DashboardLayout";

const Create = () => import(/* webpackChunkName: "respodents" */ "@/pages/Respondents/Create");
const Additional = () =>
  import(/* webpackChunkName: "respodents" */ "@/pages/Respondents/Additional");

import i18n from "../i18n";

export default {
  path: "/respondents",
  component: DashboardLayout,
  redirect: "/respondents/create",
  name: "Respondents",
  children: [
    {
      path: "create/:id",
      name: "Respondents.Create",
      component: Create,
      meta: {
        name: i18n.t("survey.step3.title.create"),
        breadcrumbs: [
          {
            name: i18n.t("dashboard.dashboard"),
            link: "Dashboard",
          },
          {
            nameGetter: "studies/getStudyName",
            disabled: true,
          },
          {
            nameGetter: "participant/getParticipantName",
            disabled: true,
          },
          {
            name: i18n.t("respondents.create"),
            link: "Respondents.Create",
          },
        ],
      },
    },
    {
      path: "additional/:id/:examRoundId",
      name: "Respondents.Additional",
      component: Additional,
      meta: {
        name: i18n.t("survey.step3.title.additional"),
        breadcrumbs: [
          {
            name: i18n.t("dashboard.dashboard"),
            link: "Dashboard",
          },
          {
            nameGetter: "studies/getStudyName",
            disabled: true,
          },
          {
            nameGetter: "participant/getParticipantName",
            link: "Exam.Show",
          },
          {
            name: i18n.t("respondents.create"),
            link: "Respondents.Additional",
          },
        ],
      },
    },
  ],
};
