<template>
  <md-toolbar
    md-elevation="0"
    class="md-transparent"
    :class="{
      'md-toolbar-absolute md-white md-fixed-top': $route.meta.navbarAbsolute,
    }"
  >
    <div class="md-toolbar-row">
      <div class="flex items-center my-2">
        <router-link :to="{ name: 'WelcomePage' }">
          <img :src="require('@/assets/images/logo.svg')" width="180px" alt="" />
        </router-link>
        <a :href="ueDocLink" target="_blank">
          <div class="topbar__european-union">
            <img :src="require('@/assets/images/UE_EFRR_rgb-1.png')" alt="" />
          </div>
        </a>
      </div>
      <div class="md-toolbar-section-end">
        <md-button
          class="md-just-icon md-round md-simple md-toolbar-toggle"
          :class="{ toggled: $sidebar.showSidebar }"
          @click="toggleSidebar"
        >
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </md-button>

        <div class="md-collapse">
          <div v-if="!isLogged" class="topbar__actions">
            <div class="mr-3">
              <md-button
                class="md-raised md-info mr-3"
                :md-ripple="false"
                @click="$router.push({ name: 'Auth.Login' })"
              >
                {{ $t("auth.login") }}
              </md-button>
            </div>
          </div>
          <div v-if="isLogged" class="topbar__actions">
            <div class="mr-3">
              <md-button
                class="md-raised md-info mr-3"
                :md-ripple="false"
                @click="$router.push({ name: 'AvailableStudies' })"
              >
                {{ $t("dashboard.available_studies") }}
              </md-button>
            </div>
            <div class="mr-5">
              <md-button
                class="md-raised md-info mr-3"
                :md-ripple="false"
                @click="$router.push({ name: 'Dashboard' })"
              >
                {{ $t("dashboard.my_studies") }}
              </md-button>
            </div>
            <md-button
              @click="$emit('showSupportTicketDialog')"
              class="md-button md-just-icon md-simple"
            >
              <md-icon>
                <md-tooltip md-direction="bottom">
                  {{ $t("support.technical_support") }}
                </md-tooltip>
                help
              </md-icon>
            </md-button>
            <span class="topbar__user"
              >{{ coachProfile.first_name }} {{ coachProfile.last_name }}</span
            >
            <md-menu md-align-trigger md-direction="bottom-end">
              <md-button class="md-button md-just-icon md-simple" md-menu-trigger>
                <md-icon class="ml-1" :md-src="require('@/assets/images/icons/user.svg')"></md-icon>
              </md-button>
              <md-menu-content>
                <md-menu-item @click="$emit('showStudiesDialog')">{{
                  $t("studies.buy_study")
                }}</md-menu-item>
                <md-divider />
                <md-menu-item @click="goFaq">{{ $t("support.faq") }}</md-menu-item>
                <md-menu-item @click="editProfile">{{ $t("profile.edit_profile") }}</md-menu-item>
                <md-menu-item @click="editPassword">{{
                  $t("profile.change_password")
                }}</md-menu-item>
                <md-divider />
                <md-menu-item @click="handleLogout">{{ $t("auth.logout") }}</md-menu-item>
              </md-menu-content>
            </md-menu>
          </div>
        </div>
      </div>
    </div>
  </md-toolbar>
</template>
<script>
import { mapActions, mapState } from "vuex";

export default {
  computed: {
    ...mapState("auth", ["user", "isLogged"]),
    ...mapState("coachProfile", ["coachProfile"]),

    ueDocLink() {
      return process.env.VUE_APP_API_URL + "/UE-doc/";
    },
  },
  async created() {
    if (this.isLogged) {
      try {
        await this.getCoachProfile({ coachId: this.user.id });
      } catch (e) {
        if (e.response?.status === 422) return;
        console.error(e);
      }
    }
  },
  methods: {
    ...mapActions("auth", ["logout"]),
    ...mapActions("coachProfile", ["getCoachProfile"]),

    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize();
      }
    },
    async handleLogout() {
      await this.logout();
      this.$router.push({ name: "Auth.Login" });
    },
    async editProfile() {
      this.$router.push({ name: "Coach.EditProfile" });
    },
    async editPassword() {
      this.$router.push({ name: "Coach.ChangePassword" });
    },
    async goFaq() {
      this.$router.push({ name: "Faq" });
    },
  },
};
</script>
<style lang="scss" scoped>
.topbar {
  &__actions {
    display: flex;
    align-items: center;
  }
  &__user {
    padding: 0 20px;
    font-size: 16px;
  }
  &__european-union {
    margin: 0 10px;
    width: 200px;

    @media (min-width: 992px) {
      max-width: 100%;
    }
  }
}
</style>
