<template>
  <div>
    <div
      id="page-container"
      class="wrapper"
      :class="[{ 'nav-open': $sidebar.showSidebar }, { rtl: $route.meta.rtlActive }]"
    >
      <div id="content-wrap">
        <div
          class="loader-overlay left-0 right-0 bottom-0 fixed z-50 flex content-center justify-center"
          v-if="isLoading"
        >
          <img :src="require('@/assets/images/icon.svg')" class="loader" width="180px" alt="" />
        </div>
        <side-bar>
          <MobileMenu />
        </side-bar>
        <DashboardNav
          @showStudiesDialog="showStudiesDialog = true"
          @showSupportTicketDialog="showSupportTicketDialog = true"
        />
        <div class="flex flex-col xl:flex-row items-center justify-space-between w-full px-12">
          <BreadCrumbs v-if="!isDashboardRoute" />
          <StudiesMenu v-if="!isCreateExamRoute" @showStudiesDialog="showStudiesDialog = true" />
        </div>
        <router-view :key="$route.path" @passUnclaimedStudy="getPassedStudyId" />
      </div>
      <FooterItem />
      <StudiesDialog
        v-if="showStudiesDialog"
        :passedStudy="passedStudyId"
        @close="showStudiesDialog = false"
      />
      <SupportTicketDialog
        v-if="showSupportTicketDialog"
        @cancel="showSupportTicketDialog = false"
      />
      <notifications position="bottom right" group="notifications" />
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import StudiesDialog from "@/pages/Study/StudiesDialog";
import SupportTicketDialog from "@/pages/Dashboard/Partials/SupportTicketDialog";
import DashboardNav from "@/pages/Dashboard/Partials/DashboardNav";
import StudiesMenu from "@/pages/Dashboard/Partials/StudiesMenu";
import MobileMenu from "@/pages/Dashboard/Partials/MobileMenu";
import BreadCrumbs from "@/pages/Dashboard/Partials/BreadCrumbs";
import FooterItem from "@/pages/Dashboard/Partials/FooterItem";

export default {
  components: {
    BreadCrumbs,
    DashboardNav,
    StudiesMenu,
    MobileMenu,
    StudiesDialog,
    SupportTicketDialog,
    FooterItem,
  },
  data() {
    return {
      showStudiesDialog: false,
      showSupportTicketDialog: false,
      passedStudyId: null,
    };
  },
  computed: {
    ...mapState("layout", ["isLoading"]),
    isDashboardRoute() {
      return this.$route.name === "Dashboard";
    },
    isCreateExamRoute() {
      return [
        "SmsExam.SmsCreate",
        "SmsExam.SmsEdit",
        "SmsExam.SmsSummary",
        "Exam.Create",
        "Exam.Edit",
        "Exam.CreateRound",
        "ExamCustomisation.Create",
        "ExamCustomisation.Edit",
        "Respondents.Create",
        "Respondents.Additional",
      ].includes(this.$route.name);
    },
  },
  methods: {
    getPassedStudyId(value) {
      this.passedStudyId = value;
      this.showStudiesDialog = true;
    },
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
  },
};
</script>
<style lang="scss">
$blue: #27569a;
$blue-darker: #1b3a68;
$gray: #8dabbf;
$scaleSize: 0.95;

.md-button {
  border-radius: 6px;
  font-size: 14px;
  &.outlined {
    background-color: transparent !important;
    color: $blue !important;
    border: 1px solid $blue;
  }
  &:disabled,
  &[disabled] {
    background-color: #acacac !important;
  }
}

.md-button.md-info {
  background-color: $blue !important;
}

@keyframes zoomIn95 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  to {
    opacity: 1;
  }
}
.main-panel .zoomIn {
  animation-name: zoomIn95;
}
@keyframes zoomOut95 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}
.main-panel .zoomOut {
  animation-name: zoomOut95;
}

.loader {
  animation: pulse 2s linear infinite;
  &-overlay {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    z-index: 50;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #eee;
  }
}

.swal2-actions {
  button {
    font-size: 13px !important;
    font-weight: 400 !important;
  }
}

body.swal2-shown > [aria-hidden="true"] {
  transition: 0.1s filter;
  filter: blur(2px);
}

@keyframes show {
  0% {
    opacity: 0;
  }
  99% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.85);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.85);
  }
}

.overlay {
  background: rgba(0, 0, 0, 0.3);
}
</style>
