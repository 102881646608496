export default {
  title: "Importuj respondentów z pliku",
  download_template: "Pobierz szablon",
  steps: {
    first: "Pobrany szablon uzupełnij danymi respondentów.",
    second: "Formatowanie danych musi być zgodne z szablonem",
    third: "Wgraj uzupełniony plik",
  },
  choose_file: "Wybierz plik z dysku",
  previous: "Poprzedni",
  next: "Następny",
  finish: "Zaimportuj",
  count: "Zaimportowano {count} respondentów",
};
