import axios from "axios";
import { env } from "@/helpers/env";

const api = axios.create({
  baseURL: env.VUE_APP_API_URL, // eslint-disable-line
  headers: {
    "Access-Control-Allow-Origin": "*",
    Accept: "application/json",
  },
});

export default api;
