export default {
  login_title: "Logowanie",
  email: "E-mail",
  password: "Hasło",
  login: "Zaloguj się",
  wrong_credentials: "Nieprawidłowe dane!",
  logout: "Wyloguj",
  forget: "Ustal nowe hasło",
  send: "Wyślij",
  forgot: "Nie pamiętasz hasła?",
  password_confirmation: "Powtórz hasło",
  reset: "Resetowanie hasła",
  registration: "Rejestracja trenera",
  complete_registration: "Ukończ rejestrację",
  register_new: "Nie masz konta? Zarejestruj się",
  account_locked:
    "Twoje konto zostało zablokowane. Prosimy o kontakt przez adres kontakt@narzedziatc.pl",
};
