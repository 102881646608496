<template>
  <div class="sidebar lg:hidden" :data-color="activeColor" :style="sidebarStyle">
    <div class="sidebar-wrapper pt-8" ref="sidebarScrollArea">
      <div v-if="isLogged" class="text-white text-center block mb-6">
        {{ $t("support.welcome") }}, {{ user.first_name }} {{ user.last_name }}
      </div>
      <slot></slot>
      <md-list class="nav">
        <slot name="links" />
      </md-list>
      <div class="absolute bottom-0 text-center text-white mb-3">
        <p class="text-sm">{{ $t("support.title") }}</p>
        <a class="support-contact text-white font-bold" href="mailto:halibut@halibut.pl"
          >halibut@halibut.pl</a
        >
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "Sidebar",
  props: {
    title: {
      type: String,
      default: "Narzędzia Trenera i Coacha",
    },
    activeColor: {
      type: String,
      default: "green",
      validator: (value) => {
        let acceptedValues = ["", "primary", "azure", "green", "orange", "danger", "rose"];
        return acceptedValues.indexOf(value) !== -1;
      },
    },
    sidebarLinks: {
      type: Array,
      default: () => [],
    },
    autoClose: {
      type: Boolean,
      default: true,
    },
  },
  provide() {
    return {
      autoClose: this.autoClose,
    };
  },
  methods: {
    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize();
      }
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
  },
  computed: {
    ...mapState("auth", ["user", "isLogged"]),
    sidebarStyle() {
      return {
        backgroundImage: `url(${this.backgroundImage})`,
      };
    },
  },
  beforeDestroy() {
    if (this.$sidebar.showSidebar) {
      this.$sidebar.showSidebar = false;
    }
  },
};
</script>
<style lang="scss">
@media (min-width: 992px) {
  .navbar-search-form-mobile,
  .nav-mobile-menu {
    display: none;
  }
}

.sidebar:after,
.off-canvas-sidebar:after {
  background-color: #054b77 !important;
}

.sidebar i.md-icon.md-theme-default.md-icon-font,
.sidebar i.material-icons,
.off-canvas-sidebar i.md-icon.md-theme-default.md-icon-font,
.off-canvas-sidebar i.material-icons {
  color: white !important;
}

.sidebar[data-color="green"] li.active > a:not([data-toggle="collapse"]),
.off-canvas-sidebar[data-color="green"] li.active > a:not([data-toggle="collapse"]) {
  background-color: #27569a !important;
  box-shadow: 0 12px 20px -10px rgba(6, 108, 158, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12),
    0 7px 8px -5px rgba(6, 108, 158, 0.2) !important;
}

.support-contact:hover {
  color: #fff !important;
}

.logo {
  @apply flex;
}
</style>
