<template>
  <md-dialog
    :md-active.sync="showDialog"
    class="studies-dialog"
    :class="{
      'studies-dialog--cart': stepsData.activeStep === 1,
      'studies-dialog--summary': stepsData.activeStep === 2,
    }"
    @md-clicked-outside="closeDialog"
    :md-fullscreen="false"
  >
    <!-- Cart -->
    <div v-show="stepsData.activeStep === 1" class="p-5" style="overflow-y: auto">
      <md-dialog-title>
        <md-icon>local_mall</md-icon>
        <span>{{ $t("studies.order.studies_purchase") }}</span>
      </md-dialog-title>
      <md-dialog-content class="p-0">
        <md-table class="table-striped">
          <md-table-row class="header-row">
            <md-table-cell
              :key="column.title"
              v-for="column in columns"
              :class="column.headerClass"
            >
              <div class="md-table-head-container">
                <div class="md-table-head-label">
                  {{ column.title }}
                </div>
              </div>
            </md-table-cell>
            <md-table-cell>
              <div class="md-table-head-container text-center">
                <div class="md-table-head-label">
                  {{ $t("studies.order.purchase") }}
                </div>
              </div>
            </md-table-cell>
            <md-table-cell>
              <div class="md-table-head-container text-right">
                <div class="md-table-head-label">
                  {{ $t("studies.order.cost") }}
                </div>
              </div>
            </md-table-cell>
          </md-table-row>
          <md-table-row :key="index" v-for="(study, index) in studies">
            <md-table-cell
              :key="column.name"
              :md-label="column.title"
              :md-numeric="column.numeric"
              md-sort-by="name"
              v-for="column in columns"
              v-html="column.calculatedValue ? column.calculatedValue(study) : study[column.name]"
              :class="column.cellClass ? column.cellClass(study) : ''"
            >
            </md-table-cell>
            <md-table-cell :md-label="$t('studies.order.purchase')" class="text-center">
              <div class="product-counter">
                <div @click="removeStudyFromOrder(study)">
                  <md-icon
                    class="md-primary"
                    :class="{
                      'md-icon__disabled': getQuantityForStudy(study) === 0,
                    }"
                    >remove</md-icon
                  >
                </div>
                <input
                  class="text-center w-8"
                  :class="{
                    'product-counter__quantity--grey': getQuantityForStudy(study) === 0,
                  }"
                  type="number"
                  min="0"
                  maxlength="999"
                  size="3"
                  @change="changeStudyQuantityInput(study, $event)"
                  :value="getQuantityForStudy(study)"
                />
                <!--                  <span-->
                <!--                      :class="{-->
                <!--                    'product-counter__quantity&#45;&#45;grey': getQuantityForStudy(study) === 0,-->
                <!--                  }"-->
                <!--                  >-->
                <!--                    {{ getQuantityForStudy(study) }}-->
                <!--                  </span>-->
                <div @click="addStudyToOrder(study)">
                  <md-icon class="md-primary">add</md-icon>
                </div>
              </div>
            </md-table-cell>
            <md-table-cell class="text-right" :md-label="$t('studies.order.cost')">
              <strong>
                {{ getAmountForStudy(study) ? getAmountForStudy(study) + " PLN" : "-" }}
              </strong>
            </md-table-cell>
          </md-table-row>
        </md-table>
        <div class="order-summary">
          <h3>{{ $t("studies.order.summary") }}</h3>
          <div
            :class="{
              'colors--green': order.amount > 0,
            }"
          >
            <span class="order-summary__amount">
              {{ order.amount > 0 ? order.amount : "-" }}
            </span>
            <span class="order-summary__currency">
              {{ order.amount > 0 ? "PLN (+VAT 23%)" : "" }}
            </span>
          </div>
        </div>
      </md-dialog-content>
      <md-dialog-actions class="p-0">
        <md-button class="md-primary outlined text-uppercase" @click="closeDialog">{{
          $t("studies.order.cancel")
        }}</md-button>
        <md-button
          class="md-primary text-uppercase"
          @click="changeStep('Summary')"
          :class="{
            disabled: !stepsData.steps[1].can(),
          }"
          >{{ $t("studies.order.go_to_summary") }}</md-button
        >
      </md-dialog-actions>
    </div>
    <!-- End of Cart -->

    <!-- Summary -->
    <div v-show="stepsData.activeStep === 2" class="p-5">
      <md-dialog-title>
        <md-icon>local_mall</md-icon>
        <span>{{ $t("studies.order.order_summary") }}</span>
      </md-dialog-title>
      <md-dialog-content class="p-0">
        <h4>
          {{ $t("studies.order.invoice_data") }}
        </h4>
        <div>
          <div class="md-layout">
            <div class="md-layout-item">
              <md-field class="md-form-group" slot="inputs">
                <label>{{ $t("studies.order.nip_number") }} </label>
                <md-input
                  v-model="form.user.coachProfile.nip_number"
                  type="number"
                  data-vv-name="nip_number"
                  required
                ></md-input>
                <md-icon class="error" v-show="errors.has('nip_number')">close</md-icon>
              </md-field>
            </div>
            <div class="md-layout-item">
              <md-field class="md-form-group" slot="inputs">
                <label>{{ $t("studies.order.business_or_company_name") }} </label>
                <md-input
                  v-model="form.user.coachProfile.company_name"
                  type="text"
                  data-vv-name="company_name"
                  required
                ></md-input>
                <md-icon class="error" v-show="errors.has('company_name')">close</md-icon>
              </md-field>
            </div>
          </div>

          <div class="md-layout">
            <div class="md-layout-item">
              <md-field class="md-form-group" slot="inputs">
                <label>{{ $t("studies.order.billing_address") }} </label>
                <md-input
                  v-model="form.user.coachProfile.address"
                  type="text"
                  data-vv-name="address"
                  required
                ></md-input>
                <md-icon class="error" v-show="errors.has('address')">close</md-icon>
              </md-field>
            </div>
          </div>
          <div class="md-layout">
            <div class="md-layout-item">
              <md-field class="md-form-group" slot="inputs">
                <label>{{ $t("studies.order.city") }} </label>
                <md-input
                  v-model="form.user.coachProfile.city"
                  type="text"
                  data-vv-name="city"
                  required
                ></md-input>
                <md-icon class="error" v-show="errors.has('city')">close</md-icon>
              </md-field>
            </div>
            <div class="md-layout-item">
              <md-field class="md-form-group" slot="inputs">
                <label>{{ $t("studies.order.postal_code") }} </label>
                <md-input
                  v-model="form.user.coachProfile.postal_code"
                  type="text"
                  data-vv-name="postal_code"
                  required
                ></md-input>
                <md-icon class="error" v-show="errors.has('postal_code')">close</md-icon>
              </md-field>
            </div>
          </div>
        </div>
        <md-table class="table-striped">
          <md-table-row :key="index" v-for="(item, index) in order.items">
            <md-table-cell>
              <div>
                {{ item.study.name }}
              </div>
            </md-table-cell>
            <md-table-cell>
              <div>
                {{ item.quantity }}
              </div>
            </md-table-cell>
            <md-table-cell class="text-right">
              <div>
                <strong>
                  {{ getAmountForStudy(item.study) ? getAmountForStudy(item.study) + " PLN" : "-" }}
                </strong>
              </div>
            </md-table-cell>
          </md-table-row>
        </md-table>
        <div class="order-summary">
          <h3>{{ $t("studies.order.total_to_be_paid") }}</h3>
          <div
            :class="{
              'colors--green': order.amount > 0,
            }"
          >
            <span class="order-summary__amount">
              {{ order.amount > 0 ? order.amount : "-" }}
            </span>
            <span class="order-summary__currency">
              {{ order.amount > 0 ? "PLN (+VAT 23%)" : "" }}
            </span>
          </div>
        </div>
      </md-dialog-content>
      <md-dialog-actions class="p-0">
        <md-button class="md-primary outlined text-uppercase" @click="changeStep('Cart')">{{
          $t("studies.order.return_to_cart")
        }}</md-button>
        <md-button class="md-primary text-uppercase" @click="goToPayment">{{
          $t("studies.order.go_to_payment")
        }}</md-button>
      </md-dialog-actions>
    </div>
    <!-- End of Summary -->
  </md-dialog>
</template>
<script>
import { mapState, mapActions, mapMutations } from "vuex";
import Vue from "vue";

export default {
  name: "StudiesDialog",
  components: {},
  props: {
    passedStudy: Number,
  },
  data() {
    return {
      passedStudyId: null,
      stepsData: {
        activeStep: 1,
        steps: [
          {
            id: 1,
            name: "Cart",
          },
          {
            id: 2,
            name: "Summary",
            can: () => {
              return this.orderHasItems();
            },
          },
        ],
      },
      columns: [
        {
          title: this.$t("studies.order.study_type"),
          name: "name",
        },
        {
          title: this.$t("studies.order.currently_have"),
          headerClass: "text-center",
          cellClass: (study) => {
            let classes = "text-center colors--blue";

            return study.purchased_amount === 0 ? classes + " colors--red" : classes;
          },
          name: "purchased_amount",
        },
        {
          title: this.$t("studies.order.study_price"),
          calculatedValue(study) {
            return study.single_study_price + " PLN";
          },
          name: "price",
        },
      ],
      showDialog: true,
      form: {
        user: {
          coachProfile: {
            nip_number: null,
            company_name: null,
            address: null,
            city: null,
            postal_code: null,
          },
        },
      },
    };
  },
  computed: {
    ...mapState("auth", ["user"]),
    ...mapState("coachProfile", ["coachProfile"]),
    ...mapState("studies", ["studies"]),
    ...mapState("order", ["order"]),
  },
  async created() {
    try {
      this.passedStudyId = this.passedStudy;
      await Promise.all([
        this.getStudies(),
        this.getCurrentOrder(),
        this.getCoachProfile({ coachId: this.user.id }),
      ]);
      const passedStudy = this.studies.filter((study) => {
        return study.id == this.passedStudyId;
      });
      if (this.passedStudyId != null) {
        this.addStudyToOrder(passedStudy[0]);
      }
      this.form.user.coachProfile = this.coachProfile.coach_profile;
    } catch (e) {
      if (e.response?.status === 422) return;
      console.error(e);
    }
  },
  methods: {
    ...mapActions("coachProfile", ["getCoachProfile"]),
    ...mapActions("studies", ["getStudies"]),
    ...mapActions("order", ["getCurrentOrder"]),
    ...mapActions("order", ["updateOrder"]),
    ...mapActions("order", ["pay"]),
    ...mapMutations("order", ["setOrder"]),

    closeDialog() {
      this.passedStudyId = null;
      this.$emit("close");
    },
    async addStudyToOrder(study) {
      if (this.passedStudyId != null) {
        const minOrderQuantity = study.min_order_quantity ? study.min_order_quantity : 1;
        await this.setStudyQuantity(study, minOrderQuantity);
        this.passedStudyId = null;
      } else {
        await this.setStudyQuantity(study, this.getQuantityForStudy(study) + 1, true);
      }
    },
    async removeStudyFromOrder(study) {
      let quantity = this.getQuantityForStudy(study);
      if (quantity === 0) {
        return false;
      }

      await this.setStudyQuantity(study, quantity - 1);
    },
    async setStudyQuantity(study, quantity, increments = false) {
      if (increments) {
        await this.updateOrder({
          study_id: study.id,
          quantity: quantity,
          operation: "INCREMENT",
        });
      } else {
        await this.updateOrder({
          study_id: study.id,
          quantity: quantity,
          operation: "DECREMENT",
        });
      }
    },
    async goToPayment() {
      try {
        const paymentUrl = await this.pay({
          payload: this.form.user,
        });

        window.location.href = paymentUrl;
      } catch (e) {
        if (e.response?.status === 422) return;
        console.error(e);
      }
    },
    changeStudyQuantityInput(study, event) {
      let minOrderQuantity = study.min_order_quantity;
      let quantity = event.target.value;

      if (quantity > 0 && quantity < minOrderQuantity) {
        Vue.notify({
          text: this.$t("studies.order.min_order_quantity_error", {
            minOrderQuantity,
          }),
          type: "error",
          group: "notifications",
        });

        this.changeLocalStudyQuantity(study, this.getQuantityForStudy(study));

        this.$forceUpdate();

        return;
      }

      this.setStudyQuantity(study, event.target.value);
    },
    changeLocalStudyQuantity(study, quantity) {
      this.order.items.find((item) => item.study_id === study.id).quantity = quantity;
    },
    getQuantityForStudy(study) {
      if (!this.studyExistsInOrder(study)) {
        return 0;
      }

      return this.order.items.find((item) => item.study_id === study.id).quantity;
    },
    getAmountForStudy(study) {
      if (!this.studyExistsInOrder(study)) {
        return 0;
      }

      let orderItem = this.order.items.find((item) => item.study_id === study.id);

      return study.single_study_price * orderItem.quantity;
    },
    studyExistsInOrder(study) {
      if (!this.order.items?.length) {
        return false;
      }

      return !!this.order.items.find((item) => item.study_id === study.id);
    },
    orderHasItems() {
      return !!this.order.items?.length;
    },
    changeStep(newStepName) {
      let nextStep = this.stepsData.steps.find((step) => step.name === newStepName);

      if (!nextStep.can || (nextStep.can && nextStep.can())) {
        this.stepsData.activeStep = nextStep.id;
      }
    },
  },
};
</script>
<style>
.md-dialog-overlay {
  background: rgba(180, 180, 180, 0.4);
  backdrop-filter: blur(2px);
}
</style>
<style lang="scss" scoped>
$black: #1d2242;
$blue: #27569a;
$blue-darker: #1b3a68;
$red: #ff6c6c;
$grey: #d2d2d2;
$grey-lighter: #f9f9f9;
$green: #62b866;

.studies-dialog {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.14);
  border-radius: 6px;
  color: $black;
  min-width: 100%;
  max-width: 100%;

  .colors {
    &--green {
      color: $green !important;
    }

    &--blue {
      color: $blue !important;
    }

    &--red {
      color: $red !important;
    }
  }

  .md-dialog-title {
    display: flex;
    padding: 0;
    font-size: 18px;
    padding-left: 5px;
    margin-bottom: 36px;

    .md-icon {
      margin: 0;
      margin-right: 7px;
      color: $black;
    }

    @media only screen and (min-width: 599px) {
      font-size: 24px;
    }
  }

  .order-summary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 42px;
    padding-bottom: 28px;
    padding-left: 16px;
    padding-right: 16px;

    h3 {
      font-weight: 700;
      font-size: 18px;

      @media only screen and (min-width: 599px) {
        font-size: 24px;
      }
    }

    &__amount {
      font-size: 18px;
      font-weight: 700;

      @media only screen and (min-width: 599px) {
        font-size: 24px;
      }
    }

    &__currency {
      font-size: 18px;
      font-weight: 400;

      @media only screen and (min-width: 599px) {
        font-size: 24px;
      }
    }
  }

  .md-dialog-actions {
    justify-content: space-between;
    padding-top: 24px;
    display: flex;
    flex-direction: column-reverse;

    @media only screen and (min-width: 599px) {
      flex-direction: row;
    }

    button {
      &:first-of-type {
        margin-top: 8px;
      }

      @media only screen and (min-width: 599px) {
        &:first-of-type {
          margin-top: 0;
        }
      }
    }

    .md-button {
      border-radius: 6px;
      font-size: 14px;
      background-color: $blue !important;
      width: 100%;

      + .md-button {
        margin-left: 0;
      }

      @media only screen and (min-width: 599px) {
        width: auto;
        min-width: 247px;

        + .md-button {
          margin-left: 8px;
        }
      }

      &.outlined {
        background-color: transparent !important;
        color: $blue !important;
        border: 1px solid $blue;
      }
    }
  }

  &--cart {
    @media only screen and (min-width: 1024px) {
      min-width: 910px;
    }

    .md-table {
      border-bottom: 1px dashed #dddddd;

      tbody {
        > tr:not(.header-row):nth-of-type(odd) {
          background-color: inherit;
        }

        > tr.header-row {
          td {
            border: none;

            .md-table-head-label {
              font-size: 16px;
              font-weight: 400;
              color: $black;
              padding-right: 0;
            }
          }
        }

        > tr {
          td {
            padding-left: 16px;
            padding-right: 16px;
            color: $black;
            font-size: 16px;
          }
        }
      }
    }

    .product-counter {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid $grey;
      border-radius: 4px;
      padding: 8px;

      input[type="number"] {
        touch-action: manipulation;
        text-rendering: optimizeLegibility;
        appearance: none;
        -moz-appearance: textfield;

        &::-webkit-inner-spin-button,
        ::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        &:focus {
          color: $black !important;
        }
      }

      .md-icon {
        cursor: pointer;
        margin: 0;
        color: $blue;

        &__disabled {
          color: $grey;
          cursor: not-allowed;
        }
      }

      &__quantity--grey {
        color: $grey;
      }
    }

    .md-dialog-actions {
      border-top: 1px dashed #dddddd;
    }
  }

  &--summary {
    @media only screen and (min-width: 599px) {
      min-width: 552px;
    }

    h4 {
      font-weight: 500;
      font-size: 16px;
      color: $blue-darker;
      padding-left: 16px;
      padding-right: 16px;
    }

    .md-layout {
      .md-layout-item {
        padding-left: 16px;
        padding-right: 16px;
      }
    }

    .md-field {
      &.md-form-group {
        &:before {
          width: 100%;
        }

        &:after {
          width: 100%;
        }
      }
    }

    .md-table {
      padding-left: 16px;
      padding-right: 16px;
      margin-top: 40px;

      &::v-deep table {
        background-color: #f9f9f9 !important;
        border-radius: 4px;

        td {
          border-top: none;
          padding-left: 0 !important;
          padding-right: 0 !important;
          padding-top: 15px !important;
          padding-bottom: 0 !important;

          &:first-of-type {
            padding-left: 16px !important;
          }

          &:last-of-type {
            padding-right: 16px !important;
          }

          > div {
            border-bottom: 1px dashed #dddddd;
            padding-bottom: 12px;
          }
        }

        tr:last-of-type td > div {
          border-bottom: none;
        }
      }
    }

    .order-summary {
      padding-top: 28px;
      padding-bottom: 0;
    }
  }
}
</style>
