import api from "@/helpers/api";

export default {
  async getParticipantNotifications({ commit }, payload) {
    const {
      data: { data },
    } = await api.get("coach/participant-notifications", { params: payload });

    commit("setParticipantNotifications", data.data);
    commit("setPagination", data);
  },
  async markAsReadParticipantNotification(state, id) {
    await api.get(`coach/participant-notifications/${id}/mark-as-read`);
  },
};
