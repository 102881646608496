<template>
  <footer id="footer" class="footer">
    <div class="footer__logo py-4">
      <img :src="require('@/assets/images/logo.svg')" alt="" />
      <span class="footer__copyrights">
        {{ $t("footer.app_name_copy") }}
        <br />
        <div style="text-align: center">
          <a :href="'mailto:kontakt@narzedziatc.pl'" style="color: #98a2b3">
            kontakt@narzedziatc.pl
          </a>
        </div>
      </span>
    </div>

    <div class="footer__content">
      <a :href="ueDocLink" target="_blank">
        <div class="footer__european-union">
          <img :src="require('@/assets/images/european-union.png')" alt="" />
        </div>
      </a>
      <ul>
        <li>
          <router-link :to="{ name: 'Unauth.PolicyPage' }">{{
            $t("footer.privacy_policy")
          }}</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'Unauth.RulesPage' }">{{ $t("footer.rules") }}</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'Unauth.CookiesPage' }">{{ $t("footer.cookies") }}</router-link>
        </li>
      </ul>
    </div>
  </footer>
</template>
<script>
export default {
  computed: {
    ueDocLink() {
      return process.env.VUE_APP_API_URL + "/UE-doc/";
    },
  },
};
</script>
<style lang="scss" scoped>
.footer {
  background: #fff;
  padding: 40px 20px 0;
  &__logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
      width: 180px;
    }
  }
  &__european-union {
    img {
      width: 380px;
    }
  }
  &__content {
    border-top: 1px solid #98a2b3;
    max-width: 1280px;
    padding-top: 10px;
    margin: 14px auto 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column-reverse;
    a {
      color: #98a2b3 !important;
      text-transform: capitalize;
      font-size: 12px;
      line-height: 24px;
      font-weight: 400;
      @media (min-width: 390px) {
        font-size: 14px;
      }
    }
    @media (min-width: 768px) {
      flex-direction: row;
    }
  }
  &__copyrights {
    font-size: 10px;
    line-height: 24px;
    margin: 15px 0 10px;
    color: #98a2b3;
    @media (min-width: 390px) {
      font-size: 12px;
    }
  }
}
</style>
