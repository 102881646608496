import auth from "./auth";
import dashboard from "./dashboard";
import global from "./global";
import survey from "./survey";
import section from "./section";
import goals from "./goals";
import notes from "./notes";
import importTranslations from "./import";
import exam_round from "./examRound";
import respondents from "./respondents";
import results from "./results";
import exam from "./exam";
import examMedia from "./examMedia";
import examCustomisation from "./examCustomisation";
import participant from "./participant";
import studyType from "./studyType";
import support from "./support";
import report from "./report";
import smsConfiguration from "./smsConfiguration";
import examRoundComment from "./examRoundComment";
import registration from "./registration";
import profile from "./profile";
import studies from "./studies";
import footer from "./footer";
import error from "./error";
import notifications from "./notifications";
import uploader from "./uploader";

export default {
  auth,
  global,
  dashboard,
  survey,
  section,
  goals,
  notes,
  import: importTranslations,
  exam_round,
  participant,
  respondents,
  results,
  exam,
  examMedia,
  examCustomisation,
  studyType,
  support,
  report,
  smsConfiguration,
  examRoundComment,
  registration,
  profile,
  studies,
  notifications,
  uploader,
  footer,
  error,
};
