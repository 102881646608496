<template>
  <div class="md-layout">
    <label v-if="label" class="md-layout-item md-size-20 md-form-label" v-html="label"></label>
    <div class="md-layout-item flex">
      <md-field
        :class="[{ 'md-error md-focused': errors.has(title) }, { 'md-valid': !errors.has(title) }]"
      >
        <md-input
          :placeholder="placeholder"
          v-model="inputValue"
          :data-vv-name="title"
          :name="title"
          :type="type"
          v-validate="validation"
          :required="required"
          :disabled="disabled"
        >
        </md-input>
        <HsValidatorStatus :field="veeFields[title]" />
      </md-field>
      <slot></slot>
    </div>
  </div>
</template>
<script>
import HsValidatorStatus from "@/components/Forms/Partials/HsValidatorStatus";

export default {
  name: "HsTextInput",
  inject: ["$validator"],
  components: {
    HsValidatorStatus,
  },
  data() {
    return {
      inputValue: "",
    };
  },
  props: {
    placeholder: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    value: {
      type: [String, Number],
      default: null,
    },
    type: {
      type: String,
      default: "text",
    },
    title: {
      type: String,
      required: true,
    },
    validation: {
      type: Object,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    value(value) {
      this.inputValue = value;
    },
    inputValue(value) {
      this.$emit("input", value);
    },
  },
  created() {
    this.inputValue = this.value;
  },
};
</script>
