import api from "@/helpers/api";

export default {
  async storeParticipant({ commit }, payload) {
    const result = await api.post(`coach/participants`, payload);
    const data = result.data.data;
    commit("setParticipant", data);
  },

  async storeParticipantDeclination(store, payload) {
    await api.post(`coach/participant-declinations`, payload);
  },

  async updateParticipantDeclination(store, { id, payload }) {
    await api.put(`coach/participant-declinations/${id}`, payload);
  },

  async getParticipant({ commit }, { id, payload }) {
    const result = await api.get(`coach/participants/${id}`, { params: payload });
    commit("setParticipant", result.data.data);
  },

  async updateParticipant(store, { id, payload }) {
    await api.put(`coach/participants/${id}`, payload);
  },
};
