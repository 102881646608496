import api from "@/helpers/api";

export default {
  async getParticipantNotes({ commit }, payload) {
    const result = await api.get("coach/participant-notes", { params: payload });

    commit("setParticipantNotes", result.data.data.data);
  },

  async createParticipantNote(store, payload) {
    const result = await api.post("coach/participant-notes", payload);

    return result.data.data;
  },

  async updateParticipantNote(store, { id, payload }) {
    await api.put(`coach/participant-notes/${id}`, payload);
  },

  async deleteParticipantNote(store, id) {
    await api.delete(`coach/participant-notes/${id}`);
  },

  async sendParticipantNote(store, id) {
    await api.post(`coach/participant-note-emails/${id}`);
  },
};
