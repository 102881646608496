<template>
  <md-card>
    <md-card-header class="md-card-header-icon md-card-header-blue">
      <div class="md-layout md-alignment-center-space-between">
        <div class="md-layout md-size-50">
          <h3 class="title">{{ title }}</h3>
        </div>
      </div>
    </md-card-header>
    <md-card-content>
      <md-progress-bar md-mode="query" v-if="isLoading"></md-progress-bar>
      <md-table class="paginated-table table-striped table-hover mt-3">
        <md-table-empty-state
          :md-description="$t('global.table.empty_description')"
          :md-label="$t('global.table.empty_label')"
          md-icon="notification_important"
        >
        </md-table-empty-state>
        <md-table-row class="header-row">
          <md-table-cell :key="column.title" v-for="column in columns">
            <div class="md-table-head-container">
              <div class="md-table-head-label my-2">
                {{ column.title }}
              </div>
            </div>
          </md-table-cell>
          <md-table-cell>
            <div class="md-table-head-container">
              <div class="md-table-head-label my-2">
                {{ $t("global.table.actions") }}
              </div>
            </div>
          </md-table-cell>
        </md-table-row>
        <md-table-row class="header-row filters-row">
          <md-table-cell :key="column.title" v-for="column in columns">
            <div class="md-table-head-container">
              <div v-if="column.filterable">
                <md-field v-if="column.name !== 'study.name' && column.name !== 'rounds.status'">
                  <md-input
                    v-model.trim="filters[column.name]"
                    @input="(value) => $emit('filter', `${column.name}:${value}`)"
                    :size="column.title.length"
                    :placeholder="$t('global.filter')"
                  >
                  </md-input>
                </md-field>
                <md-field v-if="column.name == 'study.name'">
                  <md-select
                    v-model="filters[column.name]"
                    @input="(value) => $emit('filter', `${column.name}:${value}`)"
                    :placeholder="$t('global.filter')"
                  >
                    <md-option
                      v-for="study in coachStudies"
                      v-bind:key="study.id"
                      :value="study.study.name"
                    >
                      {{ study.study.name }}
                    </md-option>
                  </md-select>
                  <button
                    v-if="
                      Object.hasOwn(filters, 'study.name') && filters['study.name'] !== undefined
                    "
                    @click="removeFilter(column.name)"
                  >
                    <md-icon>close</md-icon>
                  </button>
                </md-field>
                <md-field v-if="column.name == 'rounds.status'">
                  <md-select
                    v-model="filters[column.name]"
                    @input="(value) => $emit('filter', `${column.name}:${value}`)"
                    :placeholder="$t('global.filter')"
                  >
                    <md-option
                      v-for="status in roundStatuses"
                      :key="status.id"
                      :value="status.value"
                    >
                      {{ status.name }}
                    </md-option>
                  </md-select>
                  <button
                    v-if="
                      Object.hasOwn(filters, 'rounds.status') &&
                      filters['rounds.status'] !== undefined
                    "
                    @click="removeFilter(column.name)"
                  >
                    <md-icon>close</md-icon>
                  </button>
                </md-field>
              </div>
            </div>
          </md-table-cell>
          <md-table-cell>
            <div class="pt-2">
              <md-button class="md-simple" @click="clearFilters">
                <md-icon>filter_list</md-icon>
                {{ $t("global.clear_filters") }}
              </md-button>
            </div>
          </md-table-cell>
        </md-table-row>
        <md-table-row :key="index" v-for="(item, index) in tableData">
          <md-table-cell style="width: 1px" v-if="$scopedSlots['rowPreActions']">
            <slot name="rowPreActions" v-bind:item="item" />
          </md-table-cell>
          <md-table-cell
            :key="column.name"
            :md-label="column.title"
            :md-numeric="column.numeric"
            md-sort-by="name"
            v-for="column in columns"
            v-html="column.calculatedValue ? column.calculatedValue(item) : item[column.name]"
          >
          </md-table-cell>
          <md-table-cell :md-label="$t('global.table.actions')" v-if="$scopedSlots['rowActions']">
            <slot name="rowActions" v-bind:item="item" />
          </md-table-cell>
        </md-table-row>
      </md-table>
    </md-card-content>
    <md-card-actions md-alignment="space-between" v-if="pagination || $slots['footer-action']">
      <template v-if="pagination">
        <p class="pagination">
          {{
            $t("global.pagination", {
              from: pagination.from,
              to: pagination.to,
              total: pagination.total,
            })
          }}
        </p>
        <pagination
          :perPage="pagination.per_page"
          :total="pagination.total"
          class="pagination-no-border pagination-success"
          v-model="currentPage"
        >
        </pagination>
      </template>
      <slot name="footer-action" />
    </md-card-actions>
  </md-card>
</template>
<script>
import Pagination from "@/components/Pagination";

export default {
  name: "DataTable",
  components: {
    Pagination,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: "grid",
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    tableData: {
      type: [Array, Object],
      required: true,
    },
    pagination: {
      type: Object,
      required: false,
    },
    columns: {
      type: Array,
      default: () => [],
    },
    coachStudies: {
      type: Array,
    },
  },
  data() {
    return {
      filters: {},
      roundStatuses: [
        {
          id: 1,
          name: this.$t("exam_round.statuses.draft"),
          value: "draft",
        },
        {
          id: 2,
          name: this.$t("exam_round.statuses.not_started"),
          value: "not_started",
        },
        {
          id: 3,
          name: this.$t("exam_round.statuses.in_progress"),
          value: "in_progress",
        },
        {
          id: 4,
          name: this.$t("exam_round.statuses.first_stage_done"),
          value: "first_stage_done",
        },
        {
          id: 5,
          name: this.$t("exam_round.statuses.second_stage_done"),
          value: "second_stage_done",
        },
        {
          id: 6,
          name: this.$t("exam_round.statuses.cancelled"),
          value: "cancelled",
        },
        {
          id: 7,
          name: this.$t("exam_round.statuses.archived"),
          value: "archived",
        },
      ],
    };
  },
  computed: {
    currentPage: {
      get() {
        return this.pagination.current_page;
      },
      set(value) {
        if (value !== this.pagination.current_page) {
          this.$emit("page-change", value);
        }
      },
    },
  },
  methods: {
    removeFilter(filterName) {
      // eslint-disable-next-line no-unused-vars
      const { [filterName]: filterValue, ...newFilters } = this.filters;
      this.filters = newFilters;
      this.$nextTick(() => {
        this.$emit("update-filters", JSON.parse(JSON.stringify(this.filters)));
      });
    },
    clearFilters() {
      this.filters = {};
      this.$nextTick(() => {
        this.$emit("clear-filters");
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.animation-fb-loading {
  width: 55vw;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  .item {
    display: inline-block;
    border-radius: 3px;
    height: 20px;
    margin-top: 25px;
    background: #ccc;
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      color-stop(8%, #ededed),
      color-stop(18%, #dddddd),
      color-stop(33%, #ededed)
    );
    background: -webkit-linear-gradient(left, #ededed 8%, #dddddd 18%, #ededed 33%);
    background: linear-gradient(to right, #ededed 8%, #dddddd 18%, #ededed 33%);
    -webkit-background-size: 800px 104px;
    animation: fb-loading 5s linear;
  }

  .one {
    width: 82%;
  }

  .two {
    width: 13%;
  }
}

.empty-table-text {
  opacity: 0;
  animation: empty-table 1s 5s linear both;
}

@keyframes fb-loading {
  0% {
    background-position: -750px 0;
  }
  100% {
    background-position: 750px 0;
  }
}

@keyframes empty-table {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.md-field {
  margin: 0;
}

.md-table-head-container {
  display: flex;
  flex-direction: column;
  height: auto;
}

.header-row td {
  vertical-align: top;
}
.filters-row {
  height: 75px;
  td {
    vertical-align: middle;
  }
}
.pagination {
  font-size: 12px;
}
</style>
