export default {
  setStudy(state, data) {
    state.study = data;
  },
  clearStudy(state) {
    state.study = {};
  },
  setStudies(state, data) {
    state.studies = data;
  },
};
