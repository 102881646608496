<template>
  <ChangePasswordForm
    :errors="errors"
    @form-submit="submit"
    @cancel="$router.push({ name: 'Dashboard' })"
    :form-title="$t('profile.change_password')"
  />
</template>
<script>
import { mapActions } from "vuex";
import { successAlert } from "@/helpers/swal";
import ChangePasswordForm from "./ChangePasswordForm";

export default {
  name: "ChangePassword",
  components: {
    ChangePasswordForm,
  },
  methods: {
    ...mapActions("coachProfile", ["changeUserPassword"]),

    async submit(payload) {
      try {
        await this.changeUserPassword(payload);
        await successAlert({ title: this.$t("profile.edit_success") });
      } catch (e) {
        if (e.response?.status === 422) return;
        console.error(e);
      }
    },
  },
};
</script>
