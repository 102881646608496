export default {
  comment: "Komentarz do całości badania",
  content: "Treść",
  content_placeholder: "Wpisz treść komentarza",
  save: "Zapisz i wygeneruj raport",
  alert: {
    title: "Koniec wyników",
    text: "Czy chcesz zapisać komentarz i wygenerować raport?",
  },
};
