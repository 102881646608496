import api from "@/helpers/api";

export default {
  async createExamCustomisationAttachment(store, payload) {
    await api.post("/coach/exam-customisation-attachments", payload);
  },
  async deleteExamCustomisationAttachment(store, id) {
    await api.delete(`/coach/exam-customisation-attachments/${id}`);
  },
};
