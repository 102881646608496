<template>
  <div class="px-6 pt-6 pb-16 m-auto">
    <h3 class="font-bold text-center">
      {{ $t("support.questions_and_answers") }}
    </h3>
    <md-card
      class="w-full lg:w-2/3 xl:w-1/2 mx-auto"
      v-for="{ question, answer, id } in faq"
      :key="id"
    >
      <md-card-header>
        <div class="faq__question">
          <div class="faq__title" v-html="question" />
          <md-card-expand-trigger>
            <md-button class="md-icon-button md-just-icon md-simple">
              <md-icon>keyboard_arrow_down</md-icon>
            </md-button>
          </md-card-expand-trigger>
        </div>
      </md-card-header>
      <md-card-expand>
        <md-card-expand-content>
          <md-card-content>
            <md-divider class="mb-8" />
            <div v-html="answer" />
          </md-card-content>
        </md-card-expand-content>
      </md-card-expand>
    </md-card>
  </div>
</template>
<script>
import { mapActions, mapState, mapMutations } from "vuex";

export default {
  name: "Faq",
  components: {},
  data() {
    return {
      globalLoading: true,
    };
  },
  computed: {
    ...mapState("faq", ["faq"]),
  },
  async created() {
    try {
      this.startLoading();
      await this.getData();
    } catch (e) {
      if (e.response?.status === 422) return;
      console.error(e);
    } finally {
      this.finishLoading();
    }
  },
  methods: {
    ...mapActions("faq", ["getFaq"]),
    ...mapMutations("layout", ["startLoading", "finishLoading"]),

    async getData() {
      const defaultPayload = {
        limit: 100,
      };
      await this.getFaq({ ...defaultPayload });
    },
  },
};
</script>
<style lang="scss" scoped>
.faq {
  &__question {
    display: flex;
    justify-content: space-between;
    // align-items: center;
  }
  &__title {
    margin: 10px 0;
  }
}
</style>
