import api from "@/helpers/api";

export default {
  async getExamRoundComment({ commit }, { examRoundId }) {
    const response = await api.get(`/coach/exam-round-comments/${examRoundId}`);
    const data = response.data.data;
    commit("setExamRoundComment", data);
  },

  async createExamRoundComment(store, payload) {
    await api.post("/coach/exam-round-comments", payload);
  },

  async updateExamRoundComment(store, { id, payload }) {
    await api.put(`/coach/exam-round-comments/${id}`, payload);
  },
};
