<template>
  <div v-if="coachStudies.length > 0">
    <h4 class="purchased-studies__title font-bold">{{ $t("studies.purchased_title") }}</h4>
    <div class="purchased-studies">
      <Loader v-if="isLoading" color="#27569a" />
      <div v-else class="purchased-studies__list">
        <template v-for="coachStudy in coachStudies">
          <div
            v-if="coachStudy.all_count - coachStudy.used_count > 0"
            :key="coachStudy.id"
            class="purchased-studies__item"
          >
            <span class="purchased-studies__name">{{ coachStudy.study.name }}</span>
            <span class="purchased-studies__count">{{
              coachStudy.all_count - coachStudy.used_count
            }}</span>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Loader from "@/components/Loader";

export default {
  name: "PurchasedStudies",
  components: {
    Loader,
  },
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapState("coachStudies", ["coachStudies"]),
  },
};
</script>

<style lang="scss" scoped>
.purchased-studies {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
  @media only screen and (min-width: 1024px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    background: #fff;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.06);
  }
  &__title {
    margin: 20px 0;
    @media only screen and (min-width: 1024px) {
      margin: 0 0 20px;
    }
  }
  &__list {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    margin-top: 10px;
    @media only screen and (min-width: 1024px) {
      margin-top: 0;
    }
  }
  &__item {
    background: linear-gradient(180deg, #434f60 0%, #394554 100%);
    border-radius: 4px;
    padding: 8px 10px;
    color: #fff;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    width: 100%;
    @media only screen and (min-width: 768px) {
      width: auto;
    }
  }
  &__name {
    display: flex;
    flex: 1;
  }
  &__count {
    background: #505b6b;
    border-radius: 3px;
    width: 28px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 12px;
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
  }
}
</style>
