import DashboardLayout from "@/pages/Dashboard/Layout/DashboardLayout";
import i18n from "../i18n";

const Create = () => import(/* webpackChunkName: "exam" */ "@/pages/Exam/Create");
const Edit = () => import(/* webpackChunkName: "exam" */ "@/pages/Exam/Edit");
const EditParticipant = () => import(/* webpackChunkName: "exam" */ "@/pages/Exam/EditParticipant");
const Show = () => import(/* webpackChunkName: "exam" */ "@/pages/Exam/Show");
const Results = () => import(/* webpackChunkName: "exam" */ "@/pages/Exam/Results");
const Comment = () => import(/* webpackChunkName: "exam" */ "@/pages/Exam/Comment");
const CreateRound = () => import(/* webpackChunkName: "exam" */ "@/pages/Exam/CreateRound");
const SmsCreate = () => import(/* webpackChunkName: "smsExam" */ "@/pages/SmsExam/SmsCreate");
const SmsEdit = () => import(/* webpackChunkName: "smsExam" */ "@/pages/SmsExam/SmsEdit");
const SmsSummary = () => import(/* webpackChunkName: "smsExam" */ "@/pages/SmsExam/SmsSummary");
const SmsShow = () => import(/* webpackChunkName: "smsExam" */ "@/pages/SmsExam/SmsShow");
const SmsResults = () => import(/* webpackChunkName: "smsExam" */ "@/pages/SmsExam/SmsResults");

export default {
  path: "/survey",
  component: DashboardLayout,
  redirect: "/survey/create",
  name: "Exam",
  children: [
    {
      path: "sms-exam/:studyId",
      name: "SmsExam.SmsCreate",
      component: SmsCreate,
      meta: {
        name: i18n.t("survey.step1.title.create"),
        breadcrumbs: [
          {
            name: i18n.t("dashboard.dashboard"),
            link: "Dashboard",
          },
          {
            nameGetter: "studies/getStudyName",
            link: "SmsExam.SmsCreate",
          },
          {
            name: i18n.t("smsConfiguration.create"),
            link: "SmsConfiguration.Create",
            disabled: true,
          },
          {
            name: i18n.t("smsConfiguration.send_exam"),
            disabled: true,
          },
        ],
      },
    },
    {
      path: "sms-exam/:id/edit/:configurationId?",
      name: "SmsExam.SmsEdit",
      component: SmsEdit,
      meta: {
        name: i18n.t("survey.step1.title.edit"),
        breadcrumbs: [
          {
            name: i18n.t("dashboard.dashboard"),
            link: "Dashboard",
          },
          {
            nameGetter: "studies/getStudyName",
            link: "SmsExam.SmsEdit",
          },
          {
            name: i18n.t("smsConfiguration.create"),
            link: "SmsConfiguration.Create",
          },
          {
            name: i18n.t("smsConfiguration.send_exam"),
            disabled: true,
          },
        ],
      },
    },
    {
      path: "sms-exam/:id/summary",
      name: "SmsExam.SmsSummary",
      component: SmsSummary,
      meta: {
        name: i18n.t("survey.step1.title.edit"),
        breadcrumbs: [
          {
            name: i18n.t("dashboard.dashboard"),
            link: "Dashboard",
          },
          {
            nameGetter: "studies/getStudyName",
            link: "SmsExam.SmsEdit",
          },
          {
            name: i18n.t("smsConfiguration.create"),
            link: "SmsConfiguration.Edit",
          },
          {
            name: i18n.t("smsConfiguration.summary"),
            link: "SmsExam.SmsSummary",
          },
          {
            name: i18n.t("smsConfiguration.send_exam"),
            disabled: true,
          },
        ],
      },
    },
    {
      path: "sms-exam/:id/show",
      name: "SmsExam.SmsShow",
      component: SmsShow,
      meta: {
        name: i18n.t("exam.show"),
        breadcrumbs: [
          {
            name: i18n.t("dashboard.dashboard"),
            link: "Dashboard",
          },
          {
            nameGetter: "studies/getStudyName",
            link: "SmsExam.SmsShow",
            disabled: true,
          },
          {
            nameGetter: "participant/getParticipantName",
            link: "SmsExam.SmsShow",
          },
        ],
      },
    },
    {
      path: "sms-exam/:id/results/:roundId",
      name: "SmsExam.SmsResults",
      component: SmsResults,
      meta: {
        name: i18n.t("exam.results"),
        breadcrumbs: [
          {
            name: i18n.t("dashboard.dashboard"),
            link: "Dashboard",
          },
          {
            nameGetter: "studies/getStudyName",
            link: "SmsExam.SmsShow",
            disabled: true,
          },
          {
            nameGetter: "participant/getParticipantName",
            link: "SmsExam.SmsShow",
          },
          {
            name: i18n.t("exam.results"),
            link: "SmsExam.SmsResults",
          },
        ],
      },
    },
    {
      path: "exam/:studyId",
      name: "Exam.Create",
      component: Create,
      meta: {
        name: i18n.t("survey.step1.title.create"),
        breadcrumbs: [
          {
            name: i18n.t("dashboard.dashboard"),
            link: "Dashboard",
          },
          {
            nameGetter: "studies/getStudyName",
            link: "Exam.Create",
            disabled: true,
          },
        ],
      },
    },
    {
      path: "exam/:id/edit",
      name: "Exam.Edit",
      component: Edit,
      meta: {
        name: i18n.t("survey.step1.title.edit"),
        breadcrumbs: [
          {
            name: i18n.t("dashboard.dashboard"),
            link: "Dashboard",
          },
          {
            nameGetter: "studies/getStudyName",
            disabled: true,
          },
          {
            nameGetter: "participant/getParticipantName",
            disabled: true,
          },
          {
            name: i18n.t("exam.edit"),
            link: "Exam.Edit",
          },
        ],
      },
    },
    {
      path: "exam/:id/edit-participant",
      name: "Exam.EditParticipant",
      component: EditParticipant,
      meta: {
        name: i18n.t("survey.step1.title.edit"),
        breadcrumbs: [
          {
            name: i18n.t("dashboard.dashboard"),
            link: "Dashboard",
          },
          {
            nameGetter: "studies/getStudyName",
            disabled: true,
          },
          {
            nameGetter: "participant/getParticipantName",
            disabled: true,
          },
          {
            name: i18n.t("exam.edit_participant"),
            link: "Exam.EditParticipant",
          },
        ],
      },
    },
    {
      path: "exam/:id/show",
      name: "Exam.Show",
      component: Show,
      meta: {
        name: i18n.t("exam.show"),
        breadcrumbs: [
          {
            name: i18n.t("dashboard.dashboard"),
            link: "Dashboard",
          },
          {
            nameGetter: "studies/getStudyName",
            link: "Exam.Show",
            disabled: true,
          },
          {
            nameGetter: "participant/getParticipantName",
            link: "Exam.Show",
          },
        ],
      },
    },
    {
      path: "exam/:id/results/:roundId",
      name: "Exam.Results",
      component: Results,
      meta: {
        name: i18n.t("exam.results"),
        breadcrumbs: [
          {
            name: i18n.t("dashboard.dashboard"),
            link: "Dashboard",
          },
          {
            nameGetter: "studies/getStudyName",
            link: "Exam.Show",
            disabled: true,
          },
          {
            nameGetter: "participant/getParticipantName",
            link: "Exam.Show",
          },
          {
            name: i18n.t("exam.results"),
            link: "Exam.Results",
          },
        ],
      },
    },
    {
      path: "exam/:id/round",
      name: "Exam.CreateRound",
      component: CreateRound,
      meta: {
        name: i18n.t("exam.results"),
        breadcrumbs: [
          {
            name: i18n.t("dashboard.dashboard"),
            link: "Dashboard",
          },
          {
            nameGetter: "studies/getStudyName",
            link: "Exam.Show",
            disabled: true,
          },
          {
            nameGetter: "participant/getParticipantName",
            link: "Exam.Show",
          },
          {
            name: i18n.t("exam.create_round"),
            link: "Exam.CreateRound",
          },
        ],
      },
    },
    {
      path: "exam/:id/comment/:roundId",
      name: "Exam.Comment",
      component: Comment,
      meta: {
        name: i18n.t("examRoundComment.comment"),
        breadcrumbs: [
          {
            name: i18n.t("dashboard.dashboard"),
            link: "Dashboard",
          },
          {
            nameGetter: "studies/getStudyName",
            link: "Exam.Show",
            disabled: true,
          },
          {
            nameGetter: "participant/getParticipantName",
            link: "Exam.Show",
          },
          {
            name: i18n.t("examRoundComment.comment"),
            link: "Exam.Comment",
          },
        ],
      },
    },
  ],
};
