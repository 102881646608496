import api from "@/helpers/api";

export default {
  async getExamCustomisation({ commit }, { id }) {
    const {
      data: { data },
    } = await api.get(`coach/exam-customisations/${id}`);

    commit("setExamCustomisation", data);
  },

  async createExamCustomisation(store, { companyName, phoneNumber, examId }) {
    const result = await api.post("coach/exam-customisations", {
      company_name: companyName,
      phone_number: phoneNumber,
      exam_id: examId,
    });

    return result.data.data;
  },

  async updateExamCustomisation(store, { examCustomisationId, payload }) {
    await api.put(`coach/exam-customisations/${examCustomisationId}`, payload);
  },

  async deleteExamCustomisation(store, id) {
    await api.delete(`coach/exam-customisations/${id}`);
  },
};
