<template>
  <div v-if="!isLoading" class="welcome-page px-6 pt-10 pb-16 m-auto">
    <div class="welcome-page__section mb-12">
      <div class="welcome-page__item">
        <h2>{{ $t("dashboard.hello") }} {{ coachProfile.first_name }} 👋</h2>
        <p>{{ $t("dashboard.welcome_first_paragraph") }}</p>
        <p>{{ $t("dashboard.welcome_second_paragraph") }}</p>
        <p>{{ $t("dashboard.welcome_third_paragraph") }}</p>
        <div class="welcome-page__signature">
          <span>{{ $t("dashboard.welcome_signature") }}</span>
          <span class="welcome-page__signature--company">{{ $t("dashboard.welcome_team") }}</span>
        </div>
        <div class="welcome-page__button">
          <md-button
            class="md-raised md-info"
            :md-ripple="false"
            @click="$router.push({ name: 'Dashboard' })"
          >
            {{ $t("dashboard.go_studies") }}
          </md-button>
        </div>
      </div>
      <div class="welcome-page__item">
        <h3>Zdanie na dziś</h3>
        <p>
          {{ randomizedQuote() }}
        </p>
      </div>
      <div class="welcome-page__item">
        <p>
          {{ $t("dashboard.about_studies") }}
        </p>
      </div>
    </div>
    <h3 class="font-bold">
      {{ $t("studies.available_studies") }}
    </h3>
    <div>
      <StudiesList />
      <Alert>
        <template #title>
          {{ $t("dashboard.referal_alert_title") }}
        </template>
        <template #description>
          {{ $t("dashboard.referal_alert_description") }}
        </template>
      </Alert>
    </div>
    <div
      class="welcome-page__info"
      :style="{
        backgroundImage: `url(${require('@/assets/images/team.png')})`,
      }"
    >
      <div class="welcome-page__contact">
        <h4>{{ $t("dashboard.info") }}:</h4>
        <p>
          {{ $t("dashboard.created_by") }}:
          <span>Halibut Grupa Szkoleniowa s.c.</span>
          <span>ul. Grottgera 6/6 60-757 Poznań</span>
          <span>NIP: 779-230-54-85</span>
        </p>
      </div>
      <div class="welcome-page__contact">
        <h4>{{ $t("dashboard.contact_tutor") }}:</h4>
        <span class="welcome-page__link"><md-icon>person</md-icon> Wojciech Busiel</span>
        <a class="welcome-page__link" href="mailto:wojciech.busiel@halibut.pl"
          ><md-icon>email</md-icon> wojciech.busiel@halibut.pl</a
        >
        <a class="welcome-page__link" href="tel:606915141"><md-icon>phone</md-icon> 606 915 141</a>
      </div>
      <div class="welcome-page__contact">
        <h4>{{ $t("dashboard.contact") }}:</h4>
        <a href="mailto:halibut@halibut.pl"><md-icon>email</md-icon> halibut@halibut.pl</a>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState, mapMutations } from "vuex";
import StudiesList from "@/pages/Study/Partials/StudiesList";
import Alert from "@/components/Alert";

export default {
  name: "WelcomePage",
  components: {
    StudiesList,
    Alert,
  },
  computed: {
    ...mapState("coachProfile", ["coachProfile"]),
    ...mapState("layout", ["isLoading"]),
  },
  async created() {
    try {
      this.startLoading();
      await this.getStudies();
    } catch (e) {
      console.error(e);
    } finally {
      this.finishLoading();
    }
  },
  methods: {
    ...mapActions("studies", ["getStudies"]),
    ...mapMutations("layout", ["startLoading", "finishLoading"]),

    randomizedQuote() {
      const quotesLibrary = [
        "Nigdzie nie znajdziesz spokojniejszego miejsca odosobnienia niż w swojej własnej duszy",
        "Naucz się zwalniać. Zgubić się celowo. Obserwuj, jak oceniasz zarówno siebie, jak i otaczających cię ludzi",
        "Każdy, kto szuka prawdy, ma jedno do zrobienia: patrzeć głęboko we własną istotę. Jeśli to robi we właściwy sposób, wszystkie zagadnienia rozwiązują się same przez się.",
        "Twój umysł odpowie na większość pytań, jeśli nauczysz się relaksować i czekać na odpowiedź",
        "Naucz się odprężać i obserwować. Nie wszystko wymaga reakcji.",
      ];
      return quotesLibrary[Math.floor(Math.random() * quotesLibrary.length)];
    },
  },
};
</script>

<style lang="scss" scoped>
.welcome-page {
  max-width: 1400px;
  margin: 0 auto;
  &__section {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(3, auto-fit);
    gap: 24px;
    @media (min-width: 992px) {
      grid-template-columns: 2fr 1fr;
      grid-template-rows: repeat(2, 1fr);
    }
  }
  &__item {
    background: #ffffff;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.14);
    border-radius: 6px;
    padding: 24px;
    &:nth-child(1) {
      grid-column: 1 / 2;
      grid-row: 1 / 2;

      @media (max-width: 991px) {
        background: none;
        box-shadow: none;
        padding: 0;
      }

      @media (min-width: 992px) {
        grid-column: 1 / 2;
        grid-row: 1 / 3;
      }
    }
    &:nth-child(2) {
      background: linear-gradient(90.18deg, #27569a 0%, #1b3a68 100%), #ffffff;
      color: #fff;
      grid-column: 1 / 2;
      grid-row: 2 / 3;

      @media (min-width: 992px) {
        grid-column: 2 / 3;
        grid-row: 1 / 2;
      }
    }
    &:nth-child(3) {
      grid-column: 1 / 2;
      grid-row: 3 / 4;

      @media (min-width: 992px) {
        grid-column: 2 / 3;
        grid-row: 2 / 3;
      }
    }
    h2,
    h3 {
      font-weight: 500;
      font-size: 24px;
      line-height: 18px;
      margin-top: 5px;
      margin-bottom: 25px;
    }
    p {
      margin-bottom: 20px;
    }
  }
  &__signature {
    border-top: 1px solid #dddddd;
    padding-top: 20px;
    font-style: italic;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #064c78;
    span {
      display: block;
    }
    &--company {
      color: rgba(6, 76, 120, 0.5);
    }
  }
  &__button {
    display: flex;
    justify-content: center;
    @media (min-width: 480px) {
      justify-content: flex-end;
    }
  }
  &__info {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(3, auto-fit);
    gap: 24px;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: right bottom;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.14);
    border-radius: 6px;
    padding: 24px;
    @media (min-width: 992px) {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(1, 1fr);
    }
    @media (min-width: 1400px) {
      grid-template-columns: 1fr 1fr 2fr;
      grid-template-rows: repeat(1, 1fr);
    }
  }
  &__contact {
    border-top: 1px solid #dddddd;
    padding-top: 20px;
    margin-top: 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    @media (min-width: 992px) {
      border-top: none;
      border-left: 1px solid #dddddd;
      padding-top: 0;
      padding-left: 20px;
      margin-top: 0;
    }
    &:first-of-type {
      border: none;
      padding-top: 0;
      padding-left: 0;
      margin-top: 0;
    }
    h4 {
      font-weight: 500;
      font-size: 18px;
      line-height: 18px;
      color: #064c78;
      padding-bottom: 12px;
    }
    p {
      margin: 0;
    }
    span,
    a {
      display: block;
    }
    @media (min-width: 992px) {
      min-width: 350px;
    }
  }
  &__link {
    padding-bottom: 6px;
  }
}
</style>
