export default {
  title: "Notatki o uczestniku",
  remove_attachment: "Usuń załącznik",
  delete: "Czy na pewno chcesz usunąć tę notatkę?",
  delete_attachment: "Czy na pewno chcesz usunąć ten załącznik?",
  send: "Wyślij do uczestnika",
  send_email_title: "Na pewno?",
  send_email_alert:
    "Czy na pewno chcesz wysłać tą notatkę do uczestnika? Otrzyma on całą notatkę wraz z załącznikiem!",
  email_sent: "Pomyślnie wysłano email!",
  attachment: "Załącznik",
};
