<template>
  <div class="md-layout">
    <label class="md-layout-item md-size-20 md-form-label">
      {{ label }}
    </label>
    <div class="md-layout-item">
      <calendar :attributes="attributes" :min-date="tomorrowDate" @dayclick="onDayClick"></calendar>
    </div>
  </div>
</template>
<script>
import moment from "moment";

export default {
  name: "HsMultipleDatesDatepicker",
  props: {
    label: {
      type: String,
      default: "",
    },
    value: {
      type: [Array, String],
      default: () => [],
    },
  },

  data() {
    return {
      days: [],
    };
  },
  computed: {
    tomorrowDate() {
      const today = new Date();

      return new Date().setDate(today.getDate() + 1);
    },
    dates() {
      return this.days.map((day) => day.date);
    },
    attributes() {
      return this.dates.map((date) => ({
        highlight: true,
        dates: date,
      }));
    },
  },
  created() {
    if (this.value.length) {
      this.setDates();
    }
  },
  methods: {
    setDates() {
      const values = [...JSON.parse(this.value)];
      this.days = values.map((el) => ({
        date: moment(el).toString(),
        id: el,
      }));
    },
    onDayClick(day) {
      const idx = this.days.findIndex((d) => d.id === day.id);
      if (idx >= 0) {
        this.days.splice(idx, 1);
      } else {
        this.days.push({
          id: day.id,
          date: day.date,
        });
      }
    },
    emitChange() {
      const daysToEmit = this.days.map((day) => day.id);
      daysToEmit.sort();
      this.$emit("input", daysToEmit.length ? JSON.stringify(daysToEmit) : []);
    },
  },
  watch: {
    days() {
      this.emitChange();
    },
  },
};
</script>
