<template>
  <md-dialog
    :md-active.sync="showDialog"
    class="add-media-dialog"
    @md-clicked-outside="closeDialog"
    :md-fullscreen="false"
  >
    <div class="p-5">
      <md-dialog-title>
        <div class="flex items-center">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.09 9C9.3251 8.33167 9.78915 7.76811 10.4 7.40913C11.0108 7.05016 11.7289 6.91894 12.4272 7.03871C13.1255 7.15849 13.7588 7.52152 14.2151 8.06353C14.6713 8.60553 14.9211 9.29152 14.92 10C14.92 12 11.92 13 11.92 13M12 17H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
              stroke="#1d2242"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <span class="ml-2">{{ $t("support.technical_support") }}</span>
        </div>
      </md-dialog-title>
      <md-dialog-content class="p-0">
        <div class="w-full h-14 border border-light-grey rounded p-3 disabled" style="color: #5e5d5c">
          <md-icon>link</md-icon>
          <span> {{ this.$route.fullPath }}</span>
        </div>
        <br />
        <div class="mb-1">
          <span class="font-bold">{{ $t("support.coach.id") }}:</span>
          {{ user.id }}
        </div>
        <div class="mb-1">
          <span class="font-bold">{{ $t("support.coach.first_name") }}:</span>
          {{ user.first_name }}
        </div>
        <div class="mb-1">
          <span class="font-bold">{{ $t("support.coach.last_name") }}:</span>
          {{ user.last_name }}
        </div>
        <div class="mb-1">
          <span class="font-bold">{{ $t("support.coach.first_name") }}:</span>
          {{ user.email }}
        </div>
        <div class="mb-1">
          <span class="font-bold">{{ $t("support.coach.phone") }}:</span>
          {{ user.coach_profile.phone_number }}
        </div>
        <br />
        <md-field class="pt-6 mb-2">
          <label>{{ $t("support.enter_message") }}</label>
          <md-textarea v-model="supportMessage"></md-textarea>
        </md-field>
      </md-dialog-content>
      <md-dialog-actions class="p-0">
        <md-button class="md-primary outlined text-uppercase" type="button" @click="closeDialog">
          {{ $t("support.cancel") }}
        </md-button>
        <md-button class="md-primary text-uppercase" type="button" @click="submit">
          {{ $t("support.send_ticket") }}
        </md-button>
      </md-dialog-actions>
    </div>
  </md-dialog>
</template>
<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "SupportTicketDialog",
  data() {
    return {
      showDialog: true,
      supportMessage: "",
    };
  },
  computed: {
    ...mapState("auth", ["user"]),
  },
  methods: {
    ...mapActions("supportTicket", ["sendSupportTicket"]),
    closeDialog() {
      this.$emit("cancel");
    },
    async submit() {
      try {
        await this.sendSupportTicket({
          user_id: this.user.id,
          support_message: this.supportMessage,
          cms_data: {
            route: this.$route.fullPath,
          },
        });
        this.$notify({
          text: this.$t("support.send_success"),
          type: "success",
          group: "notifications",
        });
      } catch (e) {
        if (e.response?.status === 422) return;
        console.error(e);
      }
    },
  },
};
</script>
<style>
.md-dialog-overlay {
  background: rgba(180, 180, 180, 0.4);
  backdrop-filter: blur(2px);
}
</style>
<style lang="scss" scoped>
$black: #1d2242;
$blue: #27569a;
$blue-darker: #1b3a68;

.add-media-dialog {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.14);
  border-radius: 6px;
  color: $black;
  min-width: 100%;
  max-width: 100%;

  @media only screen and (min-width: 599px) {
    min-width: 552px;
  }

  .md-layout {
    .md-layout-item {
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  .md-field {
    &.md-form-group {
      &:before {
        width: 100%;
      }

      &:after {
        width: 100%;
      }
    }
  }

  .md-dialog-title {
    display: flex;
    padding: 0;
    font-size: 18px;
    padding-left: 5px;
    margin-bottom: 25px;

    .md-icon {
      margin: 0;
      margin-right: 7px;
      color: $black;
    }

    @media only screen and (min-width: 599px) {
      font-size: 24px;
    }
  }

  .md-dialog-actions {
    justify-content: space-between;
    padding-top: 24px;
    display: flex;
    flex-direction: column-reverse;
    border-top: 1px dashed #dddddd;

    @media only screen and (min-width: 599px) {
      flex-direction: row;
    }

    button {
      &:first-of-type {
        margin-top: 8px;
      }

      @media only screen and (min-width: 599px) {
        &:first-of-type {
          margin-top: 0;
        }
      }
    }
    .md-button {
      border-radius: 6px;
      font-size: 14px;
      background-color: $blue !important;
      width: 100%;

      + .md-button {
        margin-left: 0;
      }

      @media only screen and (min-width: 599px) {
        width: auto;
        min-width: 160px;

        + .md-button {
          margin-left: 8px;
        }
      }

      &.outlined {
        background-color: transparent !important;
        color: $blue !important;
        border: 1px solid $blue;
      }
    }
  }
}
</style>
