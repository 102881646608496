import api from "@/helpers/api";

export default {
  async getOrder(_, orderId) {
    const {
      data: { data },
    } = await api.get(`orders/${orderId}`);

    return data;
  },
  async getCurrentOrder({ commit }) {
    const {
      data: { data },
    } = await api.get("orders");

    commit("setOrder", data);
  },
  async updateOrder({ commit }, payload) {
    const {
      data: { data },
    } = await api.patch("orders", payload);

    commit("setOrder", data);
  },
  async pay(_, { orderId = null, payload }) {
    const endpoint = orderId ? `orders/pay/${orderId}` : `orders/pay`;
    const {
      data: {
        data: { redirect_url },
      },
    } = await api.post(endpoint, payload);

    return redirect_url;
  },
};
