export default {
  setExamMedia(state, data) {
    state.examMedias = data;
  },
  setPagination(state, { from, to, total, per_page, current_page }) {
    state.examMediasPagination = {
      from,
      to,
      total,
      per_page,
      current_page,
    };
  },
};
