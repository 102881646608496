<template>
  <div class="custom-alert">
    <img class="custom-alert__icon" :src="require('@/assets/images/icons/alert.svg')" alt="" />
    <div class="custom-alert__content">
      <span class="custom-alert__title">
        <slot name="title" />
      </span>
      <span class="custom-alert__description">
        <slot name="description" />
      </span>
    </div>
    <div class="custom-alert__button">
      <slot name="button" />
    </div>
  </div>
</template>

<script>
export default {
  name: "Alert",
};
</script>

<style lang="scss" scoped>
.custom-alert {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px;
  border-radius: 4px;
  margin-bottom: 40px;
  background: linear-gradient(90.18deg, #f9e7ff 0%, #dceeff 100%);
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.06);
  color: #1b3d94;
  border: 2px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(to right, #e7a4ff, #3da2ff);
  @media (min-width: 768px) {
    padding: 24px;
    flex-direction: row;
  }
  &__icon {
    margin-bottom: 16px;
    width: 64px;
    height: 64px;
    margin-right: 16px;
    @media (min-width: 768px) {
      margin-bottom: 0;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  &__title {
    display: block;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 10px;
  }
  &__description {
    display: block;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
  }
  &__button {
    margin-top: 30px;
    margin-bottom: 15px;
    @media (min-width: 768px) {
      margin-top: 10px;
    }
    button {
      padding: 14px 18px;
      color: #fff;
      background: radial-gradient(103.38% 4325.98% at 0% 0%, #aa69ff 0%, #479cff 100%);
      border-radius: 3px;
      font-weight: 500;
      font-size: 18px;
      line-height: 18px;
    }
  }
}
</style>
