export default {
  first_name: "Imię",
  last_name: "Nazwisko",
  email: "Adres email",
  status: "Status",
  actions: "Akcje",
  back: "Powrót do badania",
  delete: {
    title: "Czy na pewno chcesz usunąć tego respondenta?",
  },
  confirm: {
    title: "Czy na pewno chcesz cofnąć?",
    text: "Wpisani respondenci nie zostaną zapisani",
    confirmButtonText: "Cofnij",
  },
  create: "Dodawanie respondentów",
  tooltips: {
    reminded: "Wysłano przypomnienie",
    new: "Nowy",
    invalid: "Błędny adres e-mail",
    resigned: "Respondent zrezygnował z badania",
    fulfilled: "Wypełnił badanie",
    started: "Rozpoczął badanie",
    correct: "Otrzymał wiadomość",
    waiting: "Oczekuje na rozpoczęcie",
  },
};
